import React from 'react'
import GooglePicker from './GooglePicker'

const GoogleDrive = ({ onSelectFiles, className, isConnected, GOOGLE_CLIENT_ID }) => {
  return (
    <GooglePicker clientId={GOOGLE_CLIENT_ID}
        developerKey=''
        className={className}
        isConnected={isConnected}
        scope={['https://www.googleapis.com/auth/drive.file']}
        onChange={data => {
          if (data.docs && data.docs.length > 0) {
            onSelectFiles(data.docs.map(doc => ({
                id: doc.id,
                size: doc.sizeBytes,
                name: doc.name,
                type: doc.mimeType,
                link: doc.url,
                isDrive: true
              }))
            )
          }
        }}
        onAuthenticate={token => console.log('oauth token:', token)}
        onAuthFailed={data => console.log('on auth failed:', data)}
        multiselect={true}
        navHidden={true}
        authImmediate={false}>
      <img src={require('public/images/icon_google_drive.svg')} alt='gg_drive' className={className.img} />
    </GooglePicker>
  )
}

export default GoogleDrive
