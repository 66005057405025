import React from 'react'
import DropboxChooser from './DropboxChooser'

const Dropbox = ({ onSelectFiles, className, DROPBOX_APP_ID }) => {
  return (
    <DropboxChooser
      className={className}
      appKey={DROPBOX_APP_ID}
      linkType='direct'
      success={ files => {
        console.log('chose:', files)
        onSelectFiles(files)
      }}
      cancel={() => console.log('closed')}
      multiselect={true} >
        <img src={require('public/images/icon_dropbox.svg')} alt='dropbox' className={className.img} />
      </DropboxChooser>
  )
}

export default Dropbox
