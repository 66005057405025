import React from 'react'
import VideoResolution from './VideoResolution'
import BitrateCustom from './BitrateCustom'
import TrimContainer from './TrimContainer'
import { useSelector } from 'react-redux'
import { Checkbox, Form, Select } from 'antd'

const { Option } = Select;

const renderFormItem = (li, handleCheckBox, settings) => {
  const defaultValue = settings && settings[li.name] || li.options.default

  if (li.type === 'select') {
    if (li.name === 'audio_resolution') {
      return <VideoResolution
        li={li}
        defaultValue={settings && settings[li.name] && settings[li.name].setted || li.options.default}
        defaultWidth={settings && settings[li.name] && settings[li.name].width}
        defaultHeight={settings && settings[li.name] && settings[li.name].height}
      />
    } else if (li.name === 'audio_bitrate'
      || li.name === 'audio_bitrate_speex'
      || li.name === 'audio_bitrate_ac3'
      || li.name === 'audio_bitrate_eac3'
      || li.name === 'audio_bitrate_dca'
      || li.name === 'audio_bitrate_mp2'
      || li.name === 'audio_bitrate_aac_per_channel'
      || li.name === 'audio_bitrate_opus_per_channel'
    ) {
      return (
        <BitrateCustom
          li={li}
          defaultSliderValue={settings && settings[li.name] && settings[li.name].slider || li.min}
          defaultValue={settings && settings[li.name] && settings[li.name].setted || li.options.default}
        />
      )
    } else {
      return (
        <Select defaultValue={defaultValue} disabled={li.isDisabled}>
          {li.options.options.map(option => (
            <Option value={option.value} key={option.value}>{option.text}</Option>
          ))}
        </Select>
      )
    }
  }

  if (li.type === 'checkbox') {
    return (
      <Checkbox
        disabled={li.isDisabled}
        onChange={handleCheckBox}
        defaultChecked={settings && settings['container_trim_audio'] || false }
      >{li.text}</Checkbox>
    )
  }
  return (
    <TrimContainer
      defaultStartValue={settings && settings['container_trim_audio'] && settings['container_trim_audio'].start || ''}
      defaultEndValue={settings && settings['container_trim_audio'] && settings['container_trim_audio'].end || ''}
    />
  )
}

const checkValidate = (rule, value = {}, li) => {
  if (li.name === 'audio_resolution') {
    if (value.setted === 'custom' && (!value.width || !value.height)) {
      return Promise.reject("You should fill width and height");
    }
  }

  if (li.name === 'container_trim_audio') {
    const { start, end } = value
    const startArr = start && start.split(':')
    const endArr = end && end.split(':')
    if (start && !(/^((?:[0-9]\d+|2[0-3]):[0-5]\d:[0-5]\d$)/.test(start))) {
      return Promise.reject("Start time is not valid");
    }

    if (end && !(/^((?:[0-9]\d+|2[0-3]):[0-5]\d:[0-5]\d$)/.test(end))) {
      return Promise.reject("End time is not valid");
    }

    if (start && end && (
      (parseInt(startArr[0] * 3600) + parseInt(startArr[1] * 60) + parseInt(startArr[2]))
      >= (parseInt(endArr[0] * 3600) + parseInt(endArr[1] * 60) + parseInt(endArr[2]))
    )) {
      return Promise.reject("End time must be greater than start time");
    }
  }

  return Promise.resolve();
}


const AudioSetting = ({ form, listAudioSetting, active, isOnlyAudio, fileId }) => {
  const handleCheckBox = e => {
    form.setFieldsValue({ container_faststart_video: e.target.checked })
  }

  const { data } = useSelector(state => state.fileList)
  const settings = data && data.filter(item => item.uid === fileId)[0] && data.filter(item => item.uid === fileId)[0].settings

  return (
    <>
      {listAudioSetting.map(li => (
        <Form.Item
          hidden={active === 0 && !isOnlyAudio || (active === 1 && li.name === 'container_trim_audio') || li.isDisabled}
          label={li.label}
          valuePropName={li.name === 'container_faststart_audio' ? 'checked' : ''}
          key={li.key}
          name={li.name}
          rules={[{ validator: (rule, value) => checkValidate(rule, value, li) }]}>
          {renderFormItem(li,
            handleCheckBox,
            settings
          )}
        </Form.Item>
      )
      )}
    </>
  );
};

export default AudioSetting;
