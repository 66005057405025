import React from 'react'
import styles from './DesCard.module.sass'

const PdfManagerDes = () => (
  <div className={styles.compression_text}>
    <h3 className={styles.title_desc}>PDF Manager</h3>
    <p>PDF documents are the world’s most popular document format, and it’s not even close. Here are some PDF facts and numbers:</p>
    <ul className={styles.list}>
      <li className={styles.item}>
        <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
        <p>By 1999, already more than 100 million copies of PDF documents had been downloaded.</p>
      </li>
      <li className={styles.item}>
        <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
        <p>As of 2014, about 600 million PDF documents are in circulation on the Internet.</p>

      </li>
      <li className={styles.item}>
        <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
        <p>By 1999, already more than 100 million copies of PDF documents had been downloaded.</p>
      </li>
    </ul>
    <div className={styles.google_search_image_wrapper}>
      <img src={'/images/google_search.png'} alt="search via google" className={styles.google_search_image} />
    </div>
    <p>
      Needless to say, the popularity of PDF has already been established, and it’s even rising! Whether you are going to use PDF in school, in your business, or personal stuff like filling up Tax forms, PDF files are a great way to share and disseminate information.
    </p>
    <p>
      One of the best things about PDF is that its content does not change no matter what kind of software program or device opens it. It is not easy to edit and can be password-protected, two significant features for the security of documents that are not meant to be changed in word or format.
    </p>
    <p>
      An issue that will come across when dealing with PDFs is there are not many free <span className={styles.hight_light}>PDF editors</span> around. If you’d like a <span className={styles.hight_light}>PDF merger</span>, a PDF converter, a <span className={styles.hight_light}>PDF splitter</span>, or an all-in-one PDF editor online or offline, you may have to spend money on that service. That’s understandable because Adobe, the original developers of PDF, still owns some technologies affiliated with PDF and charges royalties to software manufacturers who’d use the technology. That is the reason why most of these programs can only read PDF files but could not edit them.
    </p>
    <p>
      Another problem that you may have to face when using a PDF editing software is you may have to utilize two or three of them to get things done. Why? Because not many of this software have ALL the tools to manage and modify PDF files effectively. For example, some could probably be a <span className={styles.hight_light}>PDF merger</span> or <span className={styles.hight_light}>PDF splitter</span>, but does not support document signing; some could do <span className={styles.hight_light}>PDF to JPG</span> or <span className={styles.hight_light}>PDF to Word</span>, but does not have <span className={styles.hight_light}>OCR</span> or <span>Optical Character Recognition.</span>
    </p>
    <p>
      In any case, having two or more software for your PDF needs is impractical and a hassle. That is why Evano’s platform gives you the PDF Manager, a powerful online PDF tool that can process all your PDF needs. And by ALL, we mean ALL. It’s a <span className={styles.hight_light}>PDF Editor</span>, <span className={styles.hight_light}>PDF Merger</span>, <span className={styles.hight_light}>PDF Splitter</span>, <span className={styles.hight_light}>Image to PDF converter</span>, and can handle conversions to and from the PDF file format.
    </p>
    <p>
      Seriously, you won’t have to look anywhere else, because we got everything in one place. And you know what the best part is? You can start using Evano PDF Manager absolutely FREE!
    </p>
    <p>
      Check out all our PDF-related tools below
    </p>
  </div>
)

export default PdfManagerDes
