import styles from '../download.module.sass'
import { Button, Col, Row } from 'antd'
import { checkExpiredDate, readableBytes } from '../../../utils'
import React from 'react'

export default function DownloadedItem ({ file }) {
  return <Row gutter={[0, 16]} className={styles.files_item} align="middle" key={file.id}>
    <Col xs={24} sm={20} md={12} className={styles.files_name}>
      <img src={'/images/file_selected.svg'} alt="converted file icon"/>
      <span>{file.name}</span>
    </Col>
    {
      file.convertStatus === 'success' ? (<>
        <Col xs={12} sm={6} className={styles.files_size}>
          {readableBytes(file.converted_size)}
        </Col>
        <Col xs={12} sm={6} className={styles.files_btn_container}>
          {/* <Button className={styles.files_btn}
                                  disabled={file.downloadText === 'Downloading'}
                                  onClick={() => onDownload(file)}>{file.downloadText}</Button> */}
          {
            checkExpiredDate(file.expired) > 0 ?
              <Button href={file.url} type="primary" className={styles.files_btn} target="_blank" download={file.name}
                      rel="noreferrer">
                Download
              </Button> :
              <p style={{ width: 100 }} className={`${styles.files_fail} mb-0`}>Expired</p>
          }
        </Col>
        {/* <Col xs={2} sm={1} onClick={() => { onDeleteFile(file) }} className={`${isDeletingMediaConverter ? `${styles.files_delete} ${styles.files_deleting}` : styles.files_delete}`}>
                                <img src={require('public/images/icon_cancel_dark.svg')} alt='cancel icon' />
                              </Col> */}
      </>) : (
        <>
          <Col xs={24} sm={12} className={styles[`files_${file.convertStatus}`]}>
            <p>
              {file.errorMessage}
            </p>
          </Col>
          {/* <Col xs={2} sm={1} className={`${isDeletingMediaConverter ? `${styles.files_delete} ${styles.files_deleting}` : styles.files_delete}`} onClick={() => { onDeleteFile(file) }}>
                                  <img src={require('public/images/icon_cancel_dark.svg')} alt='cancel icon' />
                                </Col> */}
        </>
      )
    }
  </Row>
}
