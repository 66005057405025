import styles from './style.module.sass'
import Link from 'next/link'
import { useCookies } from 'react-cookie'
import React from 'react'

export default function UploadHint ({ t }) {
  const [cookies] = useCookies(['token', 'g_token', 'g_refresh_token'])
  return <p className={styles.upload_hint}>
    <span className={styles.upload_hint_text}>{t('common:upload_hint')}</span>
    {cookies && cookies.token ? null : <span> or
            <Link href="/signup">
              <a className={styles.signup}> Sign Up</a>
            </Link>
          </span>}
  </p>
}
