import React, { useEffect, useState } from 'react'
import styles from './FileConverter.module.sass'
import SelectFormat from './SelectFormat'
import { Progress } from 'antd'
import { setFormats } from '../redux/actions/formats'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getFileFormat, getFileType, readableBytes } from '../utils'
import * as data from '../convertio_settings.json'
import ConvertSettingModal from './ConvertSettingModal'

const { ftf: { formats: { Audio, Video } = {} } = {} } = data
const AudioAndVideoFormats = Audio && Video && [...Audio, ...Video] || []

const FileConverter = ({ fileList, onDeleteFile }) => {
  const [fileFormat, setFileFormat] = useState('')
  const [fileType, setFileType] = useState('')
  const dispatch = useDispatch()
  const formatsState = useSelector(state => state.formats)
  const [openConvertSettingModal, setOpenConvertSettingModal] = useState(null)
  const router = useRouter()

  const closeSettingModal = () => setOpenConvertSettingModal(null)



  useEffect(() => {
    if (fileList.length === 1) {
      // const format = fileList[0].name.substr(fileList[0].name.lastIndexOf('.') + 1)
      const format = getFileFormat(fileList[0].name)
      // const type = fileList[0].type && fileList[0].type.split('/')[0]
      const type = getFileType(format).toLocaleLowerCase()
      setFileFormat(format)
      setFileType(type)
    } else if (fileList.length === 0 || fileList.length > 1) {
      if (fileFormat !== '') {
        setFileFormat('')
      }
      if (formatsState.formats.from !== '') {
        dispatch(setFormats({ ...formatsState.formats, from: '' }))
      }
    }
  }, [fileList])

  useEffect(() => {
    if (fileFormat !== '') {
      const formatsCodes = { ...formatsState.formats, from: fileFormat }
      dispatch(setFormats(formatsCodes))
      router.push('/[type]/[format]', `/${fileType}/${fileFormat}-online-converter`, { shallow: true })
    }
  }, [fileFormat])

  return (
    <div className={styles.converter_progress_wrapper}>
      {
        fileList.map((file, index) => (
          <div key={index} className={styles.item_converter_wrapper}>
            <div className={styles.item_converter}>
              <div className={styles.left_side}>
                <div className={styles.image_wrapper}>
                  {!file.selected &&
                    (file.uploadStatus === 'converted'
                      ? <img src={'/images/file_selected.svg'} alt='converted file icon' />
                      : <img src={'/images/file_default.svg'} alt='default file icon' />)}
                </div>
                <div className={styles.file_info}>
                  {file.uploadStatus ? <div className={(file.uploadStatus === 'convert failed' || file.uploadStatus === 'upload failed') ?
                    styles.fail_status : styles.file_upload_status}>
                    {(file.uploadStatus === 'convert failed' || file.uploadStatus === 'upload failed') ? file.errorMessage : file.uploadStatus}
                  </div> : null}
                  <div className={styles.file_name}>{file.name}</div>
                  <div className={styles.file_size}>{readableBytes(file.size)}</div>
                </div>
              </div>
              <div className={styles.convert_option}>
                <div className={styles.convert_from}>{file.name && file.name.substr(file.name.lastIndexOf('.') + 1)}</div>
                <div className={styles.to}>to</div>
                <div className={styles.convert_to}>
                  <SelectFormat setDestinationFormat={(format) => {
                      file.destination = format
                    }}
                    formatType={getFileType(getFileFormat(file.name)).toLocaleLowerCase()} format={getFileFormat(file.name)}
                    destination={file.destination}
                    fileId={file.uid || file.id}
                    converterProgress />
                </div>
              </div>
              {
                AudioAndVideoFormats.includes(file.destination && file.destination.toUpperCase())
                  ? (
                    <div className={styles.convert_setting_wrapper} onClick={() => setOpenConvertSettingModal(file.uid)}>
                      <img src={'/images/icon_setting.svg'} alt="setting icon" />
                      <span className={styles.setting_text}>Setting</span>
                    </div>
                  )
                  : null
              }

              {
                file.destination && openConvertSettingModal && <ConvertSettingModal
                  open={openConvertSettingModal === file.uid}
                  close={closeSettingModal}
                  file={file}
                />
              }
              <div className={styles.cancel}>
                <div>
                  <img onClick={() => { onDeleteFile(file) }} src={'/images/icon_cancel_dark.svg'} alt='cancel icon' />
                </div>
              </div>
            </div>
            <Progress percent={file.percent} className={styles.progress} strokeWidth={3} />
          </div>
        ))
      }

    </div>
  )
}

export default FileConverter
