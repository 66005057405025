import React from 'react'
import Lottie from 'react-lottie'
import * as animationData from './evano_loading.json'

const Loading = ({height, width}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const cHeight = height || 400
  const cWidth = width || 400

  return (
    <div>
      <Lottie options={defaultOptions} height={cHeight} width={cWidth} />
    </div>
  )
}

export default Loading
