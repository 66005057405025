/* eslint-disable */
import { useEffect, useState } from 'react'
import { Button, Checkbox, Dropdown, Menu, message, Upload } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import RemoteUpload from '../RemoteUpload'
import styles from './DragDrop.module.sass'
import Link from 'next/link'
import { useCookies } from 'react-cookie'
import { fetchJSON, getDriveUrl, getFileFormat, getFileType, hash } from '../../utils'
import useEffectAsync from '../../hooks/useEffectAsync'
import getDeviceConfig from '../../hooks/Breakpoint'
import FileConverter from '../FileConverter'
import { deleteFile, setList, setSessionConvert } from '../../redux/actions/file_list'
import { setFormats } from '../../redux/actions/formats'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'
import { fetchCloudStorage, fetchCloudStorageSuccess } from 'redux/actions/user'
import { useTranslation } from 'next-i18next'
import UploadHint from '../UploadHint'

const { Dragger } = Upload

const DragDrop = p => {
  const router = useRouter()
  const {t} = useTranslation();
  const [isBackup, setIsBackup] = useState(false)
  const [socialIds, setSocialIds] = useState({})
  const [backupProvider, setBackupProvider] = useState({})
  const [driveUrl, setDriveUrl] = useState(null)
  const fileList = useSelector(state => state.fileList.data)
  const sessionConvert = useSelector(state =>  state.fileList.sessionConvert)
  const [loading, setLoading] = useState(false)
  const [cookies, setCookie] = useCookies(['token', 'g_token', 'g_refresh_token']);
  const dispatch = useDispatch()
  const [channelId, setChannelId] = useState(null)
  const [socketUrl, setSocketUrl] = useState(null)

  const { data } = useSelector(state => state.fileList)

  const { cloudStorages, error } = useSelector(state => state.user) || {}


  const onDeleteFile = file => {
    dispatch(deleteFile(file))
    if (fileList.length === 0) {
      setLoading(false)
      dispatch(setFormats({ from: '', to: '' }))
    }
  }

  const beforeUpload = async (file, files) => {
    file.uploadStatus = null
    const newFiles = files.map(item => {
      if (item.size / 1024 / 1024 > 100) {
        item.sizeLimit = true
      }
      return item
    })

    onSelectFiles(newFiles)
    return false
  }

  let configUpload = {
    name: 'file',
    multiple: true,
    openFileDialogOnClick: false,
    showUploadList: false,
    beforeUpload
  }

  const onSelectFiles = (files) => {
    dispatch(setList([...fileList, ...files]))
    if (fileList.length === 1) {
      const format = getFileFormat(fileList[0].name)
      const type = getFileType(format).toLowerCase()
      router.push('/[type]/[format]', `/${type}/${format}-online-converter`, { shallow: true })
    }
    // setFileList(() => [...fileList, ...files])
    return false
  }

  const parseValue = (value, key) => {
    if (!value) return
    if (key === 'video_resolution') {
      return value[key]
        ? (value[key].setted === 'custom'
          ? `${value[key].width}x${value[key].height}`
          : value[key].setted)
        : value[key]
    } else {
      return value[key]
        ? (value[key].setted === 'custom'
          ? `${value[key].slider}`
          : value[key].setted)
        : value[key]
    }

  }

  const getMediaConvertId = async (file) => {
    const { destination, name, uid: fileId } = file
    const settings = data && data.filter(item => item.uid === fileId)[0] && data.filter(item => item.uid === fileId)[0].settings

    const convertSettings = {
      ...settings,
      video_bitrate: parseValue(settings, 'video_bitrate'),
      video_bitrate_h261: parseValue(settings, 'video_bitrate_h261'),
      video_resolution: parseValue(settings, 'video_resolution'),
      audio_bitrate: parseValue(settings, 'audio_bitrate'),
      audio_bitrate_speex: parseValue(settings, 'audio_bitrate_speex'),
      audio_bitrate_ac3: parseValue(settings, 'audio_bitrate_ac3'),
      audio_bitrate_eac3: parseValue(settings, 'audio_bitrate_eac3'),
      audio_bitrate_dca: parseValue(settings, 'audio_bitrate_dca'),
      audio_bitrate_mp2: parseValue(settings, 'audio_bitrate_mp2'),
      audio_bitrate_aac_per_channel: parseValue(settings, 'audio_bitrate_aac_per_channel'),
      audio_bitrate_opus_per_channel: parseValue(settings, 'audio_bitrate_opus_per_channel')
    };
    try {
      let payload = {
        // info: {
          output_format: destination,
          convert_type: 'convert',
          input_format: name.substr(name.lastIndexOf('.') + 1),
          settings: convertSettings,
          session_convert: sessionConvert
        // }
      }
      // if (isBackup) {
      //   // const backup = storages.google_drive ? 'google_drive' : 'drop_box'
      //   payload.info.backup_provider = backupProvider.id
      // }
      if (!channelId) {
        setChannelId(`convert_channel_sid_${localStorage.getItem('evano_sid')}`)
      }
      const res = await fetchJSON(`/converter/conversions`, {
        method: 'post',
        payload
      })

      if (res.data && res.data.id) {
        const newFileList = fileList.slice()
        let index = -1
        newFileList.forEach((item, fileIndex) => {
          if (item.uid === file.uid)
            index = fileIndex
        })
        if (index > -1) {
          newFileList[index].media_convert_id = res.data.id
          dispatch(setList(newFileList))
        }
      }

      return {
        mediaId: res.data.id,
        uploadUrl: res.data.work_on
      }
    } catch (error) {
      if (error && error.data) {
        message.error(error.data.message)
      }
      // setFileList(() => {
        const newFileList = fileList.slice()
        let index = -1
        newFileList.forEach((item, fileIndex) => {
          if (item.uid === file.uid)
            index = fileIndex
        })

        if (index > -1) {
          newFileList[index].errorMessage = error.data.message || 'upload failed'
          newFileList[index].uploadStatus = 'upload failed'
          newFileList[index].counted = true
          dispatch(setList(newFileList))
        }
        // return newFileList
      // })
      return false
    }
  }

  const onConverting = async (id, file) => {
    // await fetchJSON(`/media-converter/${id}/convert/`, { method: 'post' })
    setLoading(true)
  }

  const onUploading = async (file, media_convert_id, upload_url) => {
    let headers
    // const driveUrl = await getDriveUrl()
    // let action = `${driveUrl}/public-uploads`
    let action = `https://${upload_url}/v1/converter/upload-and-convert`
    // if (cookies.token) {
    //   headers = {
    //     authorization: cookies.token
    //   }
    //   action = `${driveUrl}/uploads`
    // }
    // if (file.isDrive) {
    //   action = `${driveUrl}/public-uploads-drive`
    //   if (cookies.token) {
    //     action = `${driveUrl}/uploads-drive`
    //   }
    // }
    // if (file.isUrl) {
    //   action = `${driveUrl}/public-upload-url`
    // }
    let payload = new FormData()
    payload.append('media_convert_id', media_convert_id)
    if (file.isDrive) {
      payload.append('access_token', cookies.g_token)
      payload.append('refresh_token', cookies.g_refresh_token)
      payload.append('file_id', file.id)
    } else if (file.isUrl) {
      payload.append('file_url', file.link)
    } else {
      payload.append('file', file)
    }
    payload.append('conversion', media_convert_id)
    try {
      const res = await fetchJSON(action, {
        method: 'post',
        headers: { ...headers, 'content-type': 'multipart/form-data' },
        payload,
        handleProgress: (progressEvent) => {
          const percent = Math.round(progressEvent.loaded * 100 / progressEvent.total)
          // setFileList(() => {
            const fileListArr = fileList.slice()
            fileListArr.forEach((item, index) => {
              if (item.uid === file.uid) {
                fileListArr[index].percent = percent
                // if (percent === 100) {
                fileListArr[index].uploadStatus = 'uploading'
                  // fileListArr[index].percent = 0
                  // fileListArr[index].uploadStatus = 'converting'
                // }
                dispatch(setList(fileListArr))
              }
            })
            // return fileListArr
          // })
          setLoading(true)
        }
      })
      // setLoading(false)
      if (res.status === 201) {
        // setFileList(() => {
          const fileListArr = fileList.slice()
          fileListArr.forEach((item, index) => {
            if (item.uid === file.uid) {
              fileListArr[index].uploadStatus = 'converting'
              // fileListArr[index].media_convert_id = media_convert_id
            }
          })
          dispatch(setList(fileListArr))
          // return fileListArr
        // })
        onConverting(media_convert_id, file)
      }
    } catch (err) {
      console.log(err)
      // setFileList(() => {
        const fileListArr = fileList.slice()
        fileListArr.forEach((item, index) => {
          if (item.uid === file.uid) {
            fileListArr[index].uploadStatus = 'upload failed'
            fileListArr[index].errorMessage = err.data && err.data.detail ? err.data.detail : 'upload failed'
            fileListArr[index].counted = true
            // fileListArr[index].media_convert_id = media_convert_id
          }
        })
        dispatch(setList(fileListArr))
        // return fileListArr
      // })
      setLoading(false)
    }
  }

  const startUpload = async (file) => {
    if (file.sizeLimit) {
      // setFileList(() => {
        const fileListArr = fileList.slice()
        fileListArr.forEach((item, index) => {
          if (item.uid === file.uid) {
            fileListArr[index].uploadStatus = 'upload failed'
            fileListArr[index].errorMessage = 'File size exceeds maximum limit 100 MB.'
          }
        })
        dispatch(setList(fileListArr))
        // return fileListArr
      // })
      return
    }

    const { mediaId, uploadUrl } = await getMediaConvertId(file)
    if (mediaId) {
      onUploading(file, mediaId, uploadUrl)
    }
  }
  const validateFileList = () => {
    const fileListArr = fileList.slice()
    let valid = true
    fileListArr.forEach((item, index) => {
      if (item.destination === '' ||
        item.destination === null ||
        item.destination === undefined) {
        fileListArr[index].uploadStatus = 'upload failed'
        fileListArr[index].errorMessage = 'Choose output format'
        valid = false
      }
    })
    dispatch(setList(fileListArr))
    return valid
  }
  const onConvert = () => {
    if (!validateFileList()) {
      message.error(`Please select the destination format.`)
      return
    }
    dispatch(setSessionConvert(hash(`${Math.floor(new Date().getTime()/1000)}${localStorage.getItem('evano_sid')}`)))
  }

  useEffect(() => {
    const fileListArr = fileList.slice()
    let valid = true
    fileListArr.forEach((item, index) => {
      if (item.destination === '' ||
        item.destination === null ||
        item.destination === undefined) {
        valid = false
      }
    })
    if (!valid) return
    fileList.reduce(async (previousPromise, nextFile) => {
      await previousPromise;
      return startUpload(nextFile);
    }, Promise.resolve())
  }, [sessionConvert])

  useEffect(() => {
    dispatch(setList(fileList))
  }, [fileList])

  // useEffect(() => {
  //   if (driveState.connectStatus) {
  //     message.success(driveState.connectMessage, 5, () => {
  //       dispatch(initDriveConnection())
  //     })
  //   }
  // }, [driveState.connectStatus])

  useEffectAsync(async () => {
    const urls = await getDriveUrl()
    if (urls) {
      setDriveUrl(urls.driveUrl)
      setSocketUrl(urls.socketUrl)
    }
    // const clientIds = await getSocialIds()
    // if (clientIds && Object.keys(clientIds).length > 0) {
    //   setSocialIds(clientIds)
    // }
  }, [])

  // useEffect(() => {
  //   getStorageConnected()
  // }, [driveUrl])

  // useEffect(() => {
  //   if (isConverting) {
  //     // (async function getChannelId() {
  //     //   try {
  //     //     if (isFetching) {
  //     //       const res = await fetchJSON('/websocket/', {
  //     //         method: 'get'
  //     //       })

  //     //       setChannelId(res.data && res.data.channel_id)
  //     //     }
  //     //   } catch (err) {
  //     //     console.log(err)
  //     //   }
  //     // })()
  //       setChannelId(`convert_channel_sid_${localStorage.getItem('evano_sid')}`)
  //   }
  // }, [isConverting])

  let socket

  const convertSuccess = (id, index, progress) => {
  // const result = await fetchJSON(`/converter/conversions/${id}/`, { method: 'get',
  //   headers: {
  //     authorization: `SID ${localStorage.getItem('evano_sid')}`
  //   }
  // })
    const newFileList = fileList.slice()
    // let localList = window.localStorage.getItem('files') ? JSON.parse(window.localStorage.getItem('files')) : []
    // if (!newFileList[index].counted) {
    //   localList = [{
    //     name: `${newFileList[index].name.substr(0, newFileList[index].name.lastIndexOf('.'))}.${newFileList[index].destination_format}`,
    //     format: newFileList[index].destination_format,
    //     id: newFileList[index].converted_file_id,
    //     media_id: id,
    //     url: newFileList[index].url,
    //     converted_size: newFileList[index].converted_size || newFileList[index].size,
    //     downloadStatus: '',
    //     downloadText: 'Download',
    //     convertStatus: result.data.status,
    //     errorMessage: result.data.status === 'fail' ? result.data.info.error_msg : '',
    //     expired: result.data.status === 'success' ? result.data.expired_date : ''
    //   }, ...localList].slice(0, 50)
    // }
    // window.localStorage.setItem('files', JSON.stringify(localList))
    newFileList[index].counted = true
    newFileList[index].uploadStatus = progress === 100 ? 'converted' : 'convert failed'
    const countLength = newFileList.reduce((sum, file) => {
      sum = sum + (file.counted & 1)
      return sum
    }, 0)
    if (newFileList.length === countLength) {
        console.log(sessionConvert)
        router.push('/download/[slug]', `/download/${sessionConvert}`)
        setLoading(false)
    }
    // setFileList(newFileList)
    dispatch(setList(newFileList))
    dispatch(setFormats({ from: '', to: '' }))
  }

  useEffect(() => {
    if (!socketUrl) return
    socket = io(socketUrl, {
      transports: ['websocket', 'polling'],
      path: '/v1/ws'
    });
    if (channelId) {
      socket.emit('join_channel', channelId, (ret) => {
        console.log(ret)
        socket.on(`${channelId}`, data => {
          // setFileList(() => {
            if (fileList.length === 0) {
              return
            }
            console.log('channel', fileList)
            const fileListArr = fileList.slice()
            fileListArr.forEach((item, index) => {
              if (data && item.media_convert_id === data.conversion_id) {
                fileListArr[index].percent = data.progress
              }
            })
            dispatch(setList(fileListArr))
            // return fileListArr
          // })
          let fileIndex = null
          fileList.forEach((item, index) => {
            if (data && item.media_convert_id === data.conversion_id) {
              fileIndex = index
            }
          })
          // console.log(data.data.status)
          if (fileIndex !== null && (data.progress === 100 || data.progress === -1)) {
            convertSuccess(data.conversion_id, fileIndex, data.progress)
          }
          // if (fileIndex !== null && data.data && data.data.status === 'fail') {
          //   convertFail(data.data.media_convert_id, fileIndex)
          // }
        }, error => {
          console.log('error received', error)
        })
      })
    }

    return () => socket.disconnect();

  }, [channelId, socketUrl])
  useEffect(() => {
    if (error) {
      message.error(error.message || 'Something went wrong')
    }
  }, [error])
  useEffect(() => {
    if (cloudStorages.length > 0) {
      setBackupProvider(cloudStorages[0])
    }
  }, [cloudStorages])

  const { title, subtitle } = p

  return (
    <div className={styles.drop_container_wrap}>
      <div className={styles.drop_container_top}>
        <span className={styles.red} />
        <span className={`${styles.red} ${styles.yellow}`} />
        <span className={`${styles.red} ${styles.green}`} />
      </div>
      <Dragger className={`${styles.drop_container} convert_box`} {...configUpload}>
        <h1 className={styles.title}>{title}</h1>
        <h3 className={styles.sub_title}>
          {subtitle}
        </h3>

        {fileList.length ? (
          <div className={styles.file_converter_wrapper}>
            <FileConverter
              fileList={fileList}
              onDeleteFile={onDeleteFile}
            />
          </div>
        ) : (
            <div>
              <Upload {...configUpload} openFileDialogOnClick={true}>
                <Button type='default' className={styles.btn_upload} disabled={loading} >
                  <span className={styles.upload_icon} />
                  Choose File
                </Button>
              </Upload>
            </div>
          )}

        <RemoteUpload
          onSelectFiles={onSelectFiles}
          btnAddFiles='Add more files'
          btnAction='Convert'
          hasFiles={fileList && fileList.length > 0}
          loading={loading}
          onAction={onConvert}
          storages={cloudStorages}
          socialIds={socialIds}
          configUpload={configUpload}
        />
        {(cloudStorages && cloudStorages.length > 0) && (<div className={styles.save_to}>
          <Checkbox checked={isBackup} onChange={e => {
            setIsBackup(e.target.checked)
          }}>
            Save files to&nbsp;
            <Dropdown overlay={(
              <Menu className='language_menu' defaultSelectedKeys={backupProvider.id} onClick={(e) => { setBackupProvider(cloudStorages.find(el => el.id === e.key)) }}>
                {
                  cloudStorages.map(item => {
                    return (
                      <Menu.Item className='language_menu_item' key={item.id}>
                        <img src={item.icon} alt='country' />&nbsp;<span>{item.text}</span>
                      </Menu.Item>
                    )
                  })
                }
              </Menu>
            )}>
              <Button type='default' className={styles.remote_upload_text} disabled={loading}>
                <img className={styles.country} src={backupProvider.icon} alt='country' />
                <span>&nbsp;{backupProvider.text}</span> <DownOutlined />
              </Button>
            </Dropdown>
          </Checkbox>
        </div>)}
        {/*{ isSm || isXs ?*/}
        {/*  (<p key='ads-dragdrop-mobile' className={styles.adsbygoogle_wrapper_mobile}>*/}
        {/*      <GoogleAdsense*/}
        {/*        className="adsbygoogle"*/}
        {/*        style={{display: 'block', 'min-width': '200px', 'min-height': '50px'}}*/}
        {/*        client='ca-pub-6022273213615485'*/}
        {/*        slot='5558781969'*/}
        {/*        format='auto'*/}
        {/*        responsive={true} />*/}
        {/*  </p>) :*/}
        {/*  (<p key='ads-dragdrop-desktop' className={styles.adsbygoogle_wrapper}>*/}
        {/*    <GoogleAdsense*/}
        {/*      className="adsbygoogle"*/}
        {/*      style={{display: 'block', 'min-width': '200px', 'min-height': '50px'}}*/}
        {/*      client='ca-pub-6022273213615485'*/}
        {/*      slot='1811108645'*/}
        {/*      format='auto'*/}
        {/*      responsive={true} />*/}
        {/*  </p>)*/}
        {/*}*/}
        <UploadHint t={t}/>
      </Dragger>
    </div>
  )
}
export default DragDrop
