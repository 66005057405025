import React from 'react'
import styles from './DesCard.module.sass'

const ArchiveManagerDes = () => (
  <div className={styles.compression_text}>
    {/* <h3 className={styles.title_desc}>Archive Extractor</h3> */}
    <div className={styles.content_desc}>
      <p>
        Because we are living in the Internet age, it is an absolute must to compress a file before uploading it or attaching it, perhaps, to an email. This will save you bandwidth and make everything quicker. And as you may agree, time is the most valuable commodity.
      </p>
      <p>
        Of course, you may already have one installed in your PC, but can you do the same operation on your phone (or any device) without having to install an archive file extractor/creator app? Yes, you can! With Evano’s online Archive Extractor, you now have the luxury to unzip files as quickly as you had them. Our platform functions as a ZIP extractor, RAR unzipper, 7Z opener and supports up to 70 formats!
      </p>
    </div>
    <h3 className={styles.title_desc}>What is an Archive Extractor?</h3>
    <div className={styles.content_desc}>
      <p>
        Before answering that question, let’s first define what an archive file is. An archive file is a file that is comprised of one or more computer files, including metadata. Archive files are used to compile multiple data together to make one compressed file. Archive files are created to use less storage space; the lesser the space, the more files you can store.
      </p>
      <p>
        Now, on to the question “What is an Archive Extractor?” An archive extractor is a tool that can decompress or extract all kinds of archive files. Some of the most common archive file formats are RAR, ZIP, EXE, 7Z, and more. That’s why colloquially, an archive extractor may commonly be referred to as RAR extractor, Zip Extractor, or a file unzipper.
      </p>
    </div>
  </div>
)

export default ArchiveManagerDes