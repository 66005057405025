import React, { useState } from 'react'
import { Button, Form, Modal } from 'antd'
import * as data from '../../convertio_settings.json'
import styles from './ConvertingSettingModal.module.sass'
import VideoSetting from './VideoSetting'
import AudioSetting from './AudioSetting'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettingFileList } from 'redux/actions/file_list'
import { renderSettings } from './utils.js'

const btns = [
  {
    text: "Video",
    value: 0
  },
  {
    text: "Audio",
    value: 1
  }
];

const {
  settings: {
    va: {
      video_formats_defaults = {},
      audio_formats_defaults = {},
      video_codecs_defaults = {},
    } = {}
  } = {},
  ftf: { formats: { Video: VideoFormat } = {} } = {}
} = data;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const ConvertSettingModal = ({ open, close, file, isEditAudio }) => {
  const { uid: fileId , destination } = file
  const dispatch = useDispatch()
  const [active, setActive] = useState(isEditAudio ? 1 : 0);
  const [form] = Form.useForm()
  const listVideos = renderSettings(destination, 'video')
  const listAudios = renderSettings(destination, 'audio')
  const { data } = useSelector(state => state.fileList)

  const [listAudioSetting, setListAudioSetting] = useState(listAudios)
  const [listVideoSetting, setListVideoSetting] = useState(listVideos)

  const handleChange = (changedValues) => {
    if (Object.keys(changedValues)[0] === 'audio_codec') {
      setListAudioSetting(() =>
        [...listAudioSetting].map(item => ({
          ...item,
          isDisabled: changedValues.audio_codec === 'copy' && item.name !== 'audio_codec' && item.name !== 'container_trim_audio'
            ? true
            : (item.name === 'container_faststart_audio'
              ? item.isDisabled
              : (
                audio_formats_defaults[destination.toUpperCase()] &&
                typeof audio_formats_defaults[destination.toUpperCase()][item.name] === 'undefined'
              )
            )
        })))
    }

    if (Object.keys(changedValues)[0] === 'video_codec') {
      setListVideoSetting(() =>
        [...listVideoSetting].map(item => ({
          ...item,
          isDisabled: changedValues.video_codec === 'copy' && item.name !== 'video_codec' && item.name !== 'container_trim_video'
            ? true
            : (
              item.name === 'container_faststart_video'
                ? item.isDisabled
                : !video_codecs_defaults[changedValues.video_codec] && item.name !== 'video_codec'
                  ? true
                  : (video_codecs_defaults[changedValues.video_codec] &&
                    typeof (video_codecs_defaults[changedValues.video_codec][item.name]) === 'undefined'
                  )
            )
        })))
    }
  }

  const onSettings = values => {
    dispatch(updateSettingFileList({ settings: values, fileId }))
    close()
  }

  const Header = () => {
    return (
      <div className={styles.header_wrapper}>
        <div className={styles.settings}>
          <img
            src={'/images/icon_setting.svg'}
            alt="icon setting"
            className={styles.setting_icon}
          />
          <div>Settings</div>
        </div>
        {
          VideoFormat.includes(destination.toUpperCase())
            ? btns.map(btn => (
              <Button
                className={btn.value === active ? `${styles.activeBtn} ${styles.btn} ${styles[`btn_${btn.text}`]}` : `${styles.btn} ${styles[`btn_${btn.text}`]}`}
                onClick={() => setActive(btn.value)}
                key={btn.value}
              >
                {btn.text}
              </Button>
            ))
            : <Button className={`${styles.activeBtn} ${styles.btn} ${styles.btn}`}>
              Audio
        </Button>
        }
      </div>
    );
  };

  const defaultObj = [...listVideos, ...listAudios]
    .filter(x => x.type === 'select')
    .map(x => ({
      [x.name]: x.options && x.options.default
    }))
    .reduce((acc, cur) => ({ ...cur, ...acc }), {})
  const settings = data && data.filter(item => item.uid === fileId)[0] && data.filter(item => item.uid === fileId)[0].settings
  const initialValues = settings && Object.keys(settings).length ? settings : {
    ...defaultObj,
    container_faststart_video: video_formats_defaults[destination.toUpperCase()] && video_formats_defaults[destination.toUpperCase()]['container_faststart_video'],
    container_faststart_audio: audio_formats_defaults[destination.toUpperCase()] && audio_formats_defaults[destination.toUpperCase()]['container_faststart_audio']
  }

  return (
    <Modal
      title={<Header />}
      visible={open}
      onCancel={close}
      wrapClassName="modal-convert-settings"
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSettings(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        {...layout}
        form={form}
        onValuesChange={handleChange}
        initialValues={initialValues}
      >
        {
          VideoFormat.includes(destination.toUpperCase()) && (destination.toUpperCase() !== 'M2V')
            ? (<>
              <VideoSetting
                listVideoSetting={listVideoSetting}
                form={form}
                active={active}
                fileId={fileId}
              />
              <AudioSetting
                listAudioSetting={listAudioSetting}
                form={form}
                active={active}
                fileId={fileId}
              />
            </>)
            : (
              destination.toUpperCase() === 'M2V'
                ? <VideoSetting
                  listVideoSetting={listVideoSetting}
                  form={form}
                  active={active}
                  fileId={fileId}
                />
                : <AudioSetting
                  listAudioSetting={listAudioSetting}
                  form={form}
                  active={active}
                  isOnlyAudio={true}
                  fileId={fileId}
                />
            )
        }
      </Form>
    </Modal>
  );
};

export default ConvertSettingModal;
