import { Button, Form, Input, Modal } from 'antd'
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 }
  },
};

const LinksModal = ({onSelectFiles, onCloseLinksModal, isShow, loading}) => {
  const onFinish = values => {
    console.log('Received values of form:', values);
    if (values.links) {
      onSelectFiles(values.links.map(link => {
        return {
          link
        }
      }));
    }
  };

  return (
    <Modal
      wrapClassName='links-modal'
      visible={isShow}
      footer={null}
      onCancel={onCloseLinksModal}
      closable={false}
      centered
    >
      <Form name="dynamic_form_item" initialValues={{links: ['']}} {...formItemLayoutWithOutLabel} onFinish={onFinish}>
        <Form.List name="links">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input your file url.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="https://" style={{ width: '100%' }} prefix={<LinkOutlined />}/>
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="default"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: '100%' }}
                  >
                    <PlusOutlined /> Add more url
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item className='text-center mb-0'>
          <Button type="primary" block={true} htmlType="submit" loading={loading}>
            Upload File
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LinksModal
