import React from 'react'
import { Col, Row } from 'antd'
import styles from './DesCard.module.sass'
import OptimizeDes from './OptimizeDes'
import PdfManagerDes from './PdfManagerDes'
import ArchiveManagerDes from './ArchiveManagerDes'
import AudioEditorDes from './AudioEditorDes'
import GoogleAdsense from '../Ads/GoogleAdsense'

function renderCom(name) {
  switch (name) {
    case 'Optimize':
      return <OptimizeDes />
    case 'PDF':
      return <PdfManagerDes />
    case 'Archive Manager':
      return <ArchiveManagerDes />
    case 'Audio Editor':
      return <AudioEditorDes />
    default:
      return null
  }
}

const DescCard = ({ name }) => (
  <section className={styles.section_feature_intro}>
    <div className="container">
      <Row>
        <Col
          xs={24}
          md={20}
          className={`mx-auto`}
        >
          <Row>
            <Col sm={24} md={14} lg={15} xl={17} className={styles.compression_intro}>

              {
                renderCom(name)
              }
            </Col>
            <Col sm={24} md={10} lg={9} xl={7}>
              <Row className={styles.plan}>
                {/* <Col>
                  <div className={styles.plan_heading}>
                    <img src={require('public/images/evano_logo.svg')} alt='logo' />
                    <strong>Get Premium</strong>
                  </div>
                  <div className={styles.plan_text}>Get unlimited access storage for your files. Trusted by over 2 million users every month</div>
                </Col>
                <Col>
                  <Link href='/pricing'>
                    <a className={styles.plan_link}>Get Premium</a>
                  </Link>
                </Col> */}
                <div style={{height: 300}}>
                  <GoogleAdsense
                    className="adsbygoogle"
                    style={{display: 'block', 'width': '286px', 'height': '286px'}}
                    client="ca-pub-6022273213615485"
                    slot="5367210272"
                    format="rectangle"
                    reponsive={true} />
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </section>
)

export default DescCard
