import React from 'react'
import Link from 'next/link'
import { Button, Menu } from 'antd'
import styles from '../RightMenu/style.module.sass'
import { useTranslation } from 'next-i18next'

const RightMenuMobile = ({ onClose }) => {
  const { t } = useTranslation('header')
  const toolCategory = t('tool_category', { returnObjects: true }) || t('tool_category', { returnObjects: true })

  return (
    <Menu mode={'inline'} className={styles.container} >
      <Menu.SubMenu title={t('tools')}>
        {toolCategory.map((cat) => (
          <Menu.SubMenu key={cat.item_category_head} title={cat.item_category_head}>
            {t(cat.key.toString().toLowerCase(), { returnObjects: true }).map(item => <Menu.Item key={item.item_title} onClick={onClose}>
              <Link href={item.as || item.link || ''} >
                {item.item_title}
              </Link>
            </Menu.Item>)}
          </Menu.SubMenu>
        ))}
      </Menu.SubMenu>
      <Menu.Item key="pricing">
        <Link href="/pricing">
          <a>{t('pricing')}</a>
        </Link>
      </Menu.Item>
      <Menu.Item key="about">
        <Link href="/about">
          <a>{t('about')}</a>
        </Link>
      </Menu.Item>
      <Menu.Item key="myfile" className={styles.btn_my_file_container}>
        <Link href="/download">
          <Button type="default" className={styles.btn_my_file}>
            <span className={styles.num_file}/>&nbsp;
            {t('my_files')}
          </Button>
        </Link>
      </Menu.Item>
    </Menu>
  )
}

export default RightMenuMobile
