import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { setFormats } from '../../redux/actions/formats'
import { resetFileList } from '../../redux/actions/file_list'
import styles from './style.module.sass'
import Link from 'next/link'
import { Button, Drawer, Layout } from 'antd'
import RightMenu from './RightMenu'
import RightMenuMobile from './RightMenuMobile'

export default function HeaderBar({ token }) {
  const router = useRouter()
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isTop, setIsTop] = useState(true);

  // const [files, setFiles] = useState(null)
  const handleScroll = () => {
    setIsTop(pageYOffset < 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem('files')) {
  //     setFiles(localStorage.getItem('files'))
  //   }
  // }, [])

  const onHomeClick = () => {
    dispatch(setFormats({ from: "", to: "" }));
    dispatch(resetFileList())
    // localStorage.removeItem('files')
    setTimeout(() => {
      router.push('/')
    }, 100);
  };

  return (
    <nav className={`nav_bar fixed_top ${isTop ? "" : "filled"}`}>
      <Layout.Header className="container d-flex">
        <div className={styles.logo}>
          <Link href="/">
            <a onClick={onHomeClick}>
              <img src={require('public/images/logo_light.svg')} alt="logo"  width={172} height={37} />
            </a>
          </Link>
          {/* <Link href="/download">
            <Button type="default" className={styles.btn_my_file}>
              <span className={styles.num_file}></span>&nbsp;
              {t("header:my_files")}
            </Button>
          </Link> */}
        </div>
        <div className={styles.right_menu}>
          <RightMenu mode="horizontal" token={token} />
        </div>
        <Button
          className={styles.navbar_toggle}
          onClick={() => setVisible(true)}
        >
          <span className={styles.navbar_toggle_icon} />
        </Button>
      </Layout.Header>
      <Drawer
        className="evano_drawer"
        title=""
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        width={'80%'}
        visible={visible}
      >
        <RightMenuMobile onClose={()=>setVisible(false)}/>
      </Drawer>
    </nav>
  );
}
