import React, { useEffect, useState } from 'react'
import { Col, Input, message, Popover, Row } from 'antd'
import styles from './SelectFormat.module.sass'
import { fetchJSON } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { setFormats } from '../../redux/actions/formats'
import { setList } from '../../redux/actions/file_list'

const { Search } = Input

const SelectFormat = (props) => {
  const dispatch = useDispatch()
  const formatsState = useSelector(state => state.formats)
  const [activeType, setActiveType] = useState(props.formatType)
  const [activeFormat, setActiveFormat] = useState(props.destination || '')
  const [extensions, setExtensions] = useState({})
  const [show, setShow] = useState(false)
  const [searchFormat, setSearchFormat] = useState('')
  const { setDestinationFormat } = props

  useEffect(() => {
    const from = formatsState.formats.from
    let isFetching = true
    // const {format} = props
    let format = from && from !== '' ? from : props.format

    if (format && format !== '') {
      (async function getFormats() {
        try {
          if (isFetching) {
            const res = await fetchJSON(`/file-format/file-formats/${format}/converted-targets`, {
              method: 'get'
            })
            if (res.data) {
              setExtensions(res.data)
            }
          }
        } catch (error) {
          if (error.status === 404) {
            dispatch(setList([]))
            dispatch(setFormats({ from: '', to: '' }))
            message.error(`Format ${format} is not supported`)
          } else {
            console.log(error)
          }
        }
      })()
    }
    return () => {
      isFetching = false
    }
  }, [])
  useEffect(() => {
    if (formatsState.formats.to  !== '' && formatsState.formats.fileId === props.fileId) {
      setActiveFormat(formatsState.formats.to)
      setDestinationFormat(formatsState.formats.to)
    }
  }, [formatsState.formats.to])

  // const onChange = () => {
  //   // setShow(!show)
  //   const formatsCodes = { ...formatsState.formats, to: activeFormat }
  //   dispatch(setFormats(formatsCodes))
  // }

  const onSearch = (value) => {
    getExtensions(value)
  }

  const handleActiveformat = (format) => {
    // setActiveFormat(format)
    const formatsCodes = { ...formatsState.formats, to: format, fileId: props.fileId }
    dispatch(setFormats(formatsCodes))
    setShow(!show)
    setSearchFormat('')
    // setDestinationFormat(format)
  }

  const getExtensions = (value) => {
    let searchVals;

    if (value !== '') {
      searchVals = extensions[activeType].filter((i) => i && i.toUpperCase() && i.toUpperCase().includes(value.toUpperCase()))

      if (searchVals && searchVals.length) {
        setExtensions(() => ({ ...extensions, searchVals}))
      } else {
          setExtensions(() => ({ ...extensions, searchVals: []}))
      }

    } else {
      setExtensions(() => ({ ...extensions, searchVals: extensions[activeType]}))
    }
  }

  const handleSearchChange =(e) => {
    const { value } = e.target
    setSearchFormat(value)
    getExtensions(value)
  }

  const formatExtension = extensions['searchVals'] || extensions[activeType] || []

  useEffect(() => {
    if (!show) {
      setExtensions(() => ({ ...extensions, searchVals: null}))
    }
  }, [show])

  useEffect(() => {
    setSearchFormat(null)
    setExtensions(() => ({ ...extensions, searchVals: extensions[activeType]}))
  }, [activeType])

  return (
    <Popover
      trigger='click'
      visible={show}
      onVisibleChange={() => {
        setShow(!show)
        setSearchFormat('')
      }}
      overlayClassName={styles.select_popover}
      destroyTooltipOnHide
      placement='bottom'
      content={
        <div className={styles.wrap}>
          <Row align='stretch'>
            <Col flex='75px' className={styles.types_wrap}>
              <div className={styles.types_title}>select</div>
              <div className={styles.types}>
                {Object.keys(extensions)
                .filter(extension => extension !== 'searchVals')
                .map((type) => {
                  if (type !== 'code' && extensions[type].length > 0) {
                    return (
                      <div
                        className={activeType === type ? [styles.types_item, styles.active].join(' ') : styles.types_item}
                        key={type}
                        onClick={() => setActiveType(type)}
                      >
                        {type}
                      </div>
                    )
                  }
                })}
              </div>
            </Col>
            <Col flex='1'>
              <div className={styles.formats}>
                <div className={styles.formats_search}>
                  <Search
                    placeholder='Format'
                    onSearch={value => onSearch(value)}
                    className={styles.search}
                    value={searchFormat}
                    onChange={handleSearchChange}
                    allowClear
                    size="large"
                  />
                </div>
                <Row align='middle' gutter={[8, 8]} className={styles.formats_row}>
                  {formatExtension.map((format) => (
                      <Col key={format}>
                        <div
                          className={activeFormat === format ? [styles.formats_item, styles.active].join(' ') : styles.formats_item}
                          onClick={() => handleActiveformat(format) }
                        >
                          {format}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      }
    >
      <div className={props.converterProgress ? styles.select_btn_cv : styles.select_btn} onClick={() => setShow(!show)}>
        {activeFormat && activeFormat !== '' ? activeFormat.toUpperCase() : 'Select'}
      </div>
    </Popover>

  )
}

export default SelectFormat
