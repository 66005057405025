import * as data from '../../convertio_settings.json'

const {
  settings: {
    va: {
      video_codecs_available = {},
      audio_codecs_available = {},
      video_formats_defaults = {},
      audio_formats_defaults = {},
      video_codecs_defaults = {},
      settings = {}
    } = {}
  } = {},
  ftf: { formats: { Video: VideoFormat } = {} } = {}
} = data;

const {
  video_codec: { options } = {},
  audio_codec: { options: audio_codec_options } = {},
  order: { Video, Audio } = {},
} = settings;

export function getDefaultValue(name = '', code_format = '') {
  if (name.indexOf('audio') > -1) {
    if (!VideoFormat.includes(code_format)) {
      return (audio_formats_defaults[code_format.toUpperCase()] &&
        audio_formats_defaults[code_format.toUpperCase()][name])
    } else {
      return (audio_formats_defaults[video_formats_defaults[code_format.toUpperCase()].audio] &&
        audio_formats_defaults[video_formats_defaults[code_format.toUpperCase()].audio][name]
      )
    }
  }


  if (name.indexOf('video') > -1) {
    return video_codecs_defaults[getOptions('video_codec', code_format).default]
      && video_codecs_defaults[getOptions('video_codec', code_format).default][name]
      ? `${video_codecs_defaults[getOptions('video_codec', code_format).default][name]}` : ''

  }

}

export function getOptions(name = '', code_format = '') {
  if (name === "video_codec") {
    const code_format_available =
      video_codecs_available[code_format.toUpperCase()] || [];
    const codec_default =
      (video_formats_defaults[code_format.toUpperCase()] &&
        video_formats_defaults[code_format.toUpperCase()].video) ||
      "";

    return {
      default: codec_default,
      options: [{
        value: 'copy',
        text: "Without reencoding (Copy video from input file)"
      }, ...code_format_available.map(x => ({
        value: x,
        text: options[x]
      }))]
    };
  }

  if (name === "audio_codec") {
    const code_format_available =
      audio_codecs_available[code_format.toUpperCase()] || [];
    const codec_default =
      (
        audio_formats_defaults[code_format.toUpperCase()]
        && audio_formats_defaults[code_format.toUpperCase()].audio_codec)
      || (
        video_formats_defaults[code_format.toUpperCase()] &&
        video_formats_defaults[code_format.toUpperCase()].audio
      );
    return {
      default: codec_default,
      options: [{
        value: "copy",
        text: "Without reencoding (Copy audio from input file)"
      }, ...code_format_available.map(x => ({
        value: x,
        text: audio_codec_options[x]
      }))]
    };
  }


  return {
    default: getDefaultValue(name, code_format) || "",
    options: Object.keys(settings[name].options).map(x => ({
      value: x,
      text: settings[name].options[x]
    })) || []
  };

}


export function checkDisable(item, to = '') {
  const extension = to.toUpperCase()
  if ((item === 'video_profile' && video_formats_defaults[extension]
    && video_formats_defaults[extension].video !== 'H264'
  )
    || (
      item === 'video_profile_level' && (extension !== 'H264')
      && video_formats_defaults[extension]
      && video_formats_defaults[extension].video !== 'H264'
    )
    || (item === 'container_faststart_video'
      && video_formats_defaults[extension]
      && !video_formats_defaults[extension]['container_faststart_video'])
    || (!VideoFormat.includes(extension) && audio_formats_defaults[extension] && (
      typeof (audio_formats_defaults[extension][item]) === 'undefined')
    )
    || (item === 'video_bitrate_h261' || item === 'video_resolution_h261')
    || (item === 'video_resolution_h263')
    || (item === 'video_crf_vpx')
    || (VideoFormat.includes(extension) && (item.indexOf('audio') > -1)
      && typeof (audio_formats_defaults[video_formats_defaults[extension].audio][item]) === 'undefined'
    )
    || (item === 'video_qscale_theora')
  ) {
    return true;
  }

  return false;
}

export function renderSettings(container, type) {
  const TypeConvert = type === 'video' ? Video : Audio;
  return TypeConvert
    .map((item, index) => ({
      key: index,
      label: settings[item].label,
      name: item,
      type: settings[item].type,
      text: settings[item].text,
      options: settings[item].type === 'select' && getOptions(item, container),
      isDisabled: checkDisable(item, container),
      min: settings[item].custom && settings[item].custom.min,
      max: settings[item].custom && settings[item].custom.max,
      step: settings[item].custom && settings[item].custom.step
    }));
}
