import React from 'react'
import { Col, Row } from 'antd'
import styles from './style.module.sass'
import Link from 'next/link'
import getDeviceConfig from '../../../hooks/Breakpoint'

const Editor = ({ editors, setActive, active }) => {
  console.log('editors', editors)
  const { isSm, isXs } = getDeviceConfig()
  if (isSm || isXs) {
    return (
      <>
        {editors.map((editor, index) => (
          <div key={index} className={styles.editor_mobile}>
            <Link href={editor.link}>
              <h5 className={styles.editor_heading_mobile}>{editor.item_title}</h5>
            </Link>
          </div>
        ))}
      </>
    )
  }
  return (
    <>
      {editors.map((editor, index) => (
        <Link href={editor.link} key={editor.link}>
          <Row
            justify='center'
            className={styles.editorContainer}
            key={index}
            onMouseOut={() => setActive(index)}
          >
            <Col span={12} className={styles.content}>
              <h2 className={styles.heading}>{editor.item_title}</h2>
              <p className={styles.description}>{editor.description}</p>
            </Col>
            <Col span={12}>
              <Row justify='end' className={styles.logo_wrapper}>
                <div>
                  <img src={editor.logo} className={styles.logo} alt='editor' />
                </div>
              </Row>
            </Col>
          </Row>
        </Link>
      ))}
    </>
  )
}

export default Editor
