import React from 'react'
import styles from './DesCard.module.sass'

const OptimizeDes = () => (
  <div className={styles.compression_text}>
    <h3 className={styles.title_desc}>GIF Optimizer, Meme Generator & JPG File Converter</h3>
    <div className={styles.content_desc}>
      <p>
        In the Internet world, we doubt that there is still one living being that is not aware of the existence of GIFs. GIF, short for Graphics Internet Format, debuted in June 1987 and my oh my, who would have known that it would define Internet culture over thirty years after its release
      </p>
      <p>
        In fact, GIFs have become so popular that Google decided to have a GIF database of its own. In 2018, they acquired Tenor, a GIF platform that had as many as 300 million active users and 12 billion searches per month.
      </p>
      <p>Now, let’s have a little fun and dig some GIF fun facts that you may not have known:</p>
      <ul className={styles.list}>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>When GIF was created in 1987, there were still no browsers around. People had to use RELAY, CompuServe, and bulletin board systems.</p>
        </li>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>It was two years after (that was in 1989), with the creation of the GIF89a specification, that GIF fully supported animated images.</p>
        </li>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>The use of GIFs started dropping in 2006 up to 2012. However, in 2013, it orchestrated a comeback when it was used by marketers in place of emoticons to convey more complicated forms of expression. Safe to say it hasn’t looked back since!</p>
        </li>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>According to its original developer Steve Wilhite, it is pronounced as JIF. The hard “G” as in GIF, became a more popular pronunciation to avoid confusion with the peanut butter brand JIF.</p>
        </li>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>In late 2016, Giphy was reported to serve 1 billion GIFs daily for 100 million users.</p>
        </li>
      </ul>
      <p>Because GIFs are basically the language of the Internet, it has been integrated into social media (e.g., reaction GIFs) or as marketing tools. Whatever purpose it serves, there is now a growing need for people to create or optimize GIFs. That is why a tried-and-true GIF maker, GIF generator, and similar tools are a necessity for the geeks and entrepreneurs in us.</p>
      <p>
      Fortunately, Evano offers a reliable option as far as being a <span className={styles.hight_light}>GIF creator</span> or a <span className={styles.hight_light}>GIF Compressor</span>. In fact, it does even more than a <span className={styles.hight_light}>GIF editor online</span>. It can also optimize other image formats such as JPG, PNG, and WebP. So aside from being a <span className={styles.hight_light}>GIF optimizer</span>, our tool is also a <span className={styles.hight_light}>JPG file converter</span>, a <span className={styles.hight_light}>PNG Compressor</span>, and a <span className={styles.hight_light}>WebP converter</span> and <span className={styles.hight_light}>optimizer</span>. And because all of our tools are browser-based, including the all-in-one <span className={styles.hight_light}>GIF optimizer</span>, <span className={styles.hight_light}>Meme Generator</span>, and JPG file converter, you can access it anytime you want, and from any device you can get your hands on. If you don’t call that convenient, we don’t know what is.
      </p>
    </div>
    <h3 className={styles.title_desc}>Why Optimize Images</h3>
    <div className={styles.content_desc}>
      <p>Whatever file format you use-- GIF, JPG, PNG, or WebP-- at one point or another, you may need to optimize them. To “optimize” means to render images in the right format, resolution, size, and dimensions. The goal is to achieve the smallest file size possible without the tradeoff in quality.</p>
      <p>Optimization is vital for website development since images can affect the loading speed, and loading speed has a direct effect on user experience. A mere three seconds of loading time could mean a potential customer loss. If you’re using animated GIFs as marketing tools, for example, you may put such a heavy load on a web page or an email.</p>
      <p>On a more personal level, social media platforms do have file size limits and image dimension parameters that you need to follow. Facebook, for example, only allows 180 pixels x 180 pixels for profile pictures while Instagram allows 110x110 for profile pictures and a maximum of 1080x1080 on uploaded photos. Twitter only allows GIF files of up to 5MB on mobile and 15 MB on the web. Needless to say, you have to be mindful of the image file size limits on social media to get the most out of these platforms. Thus, you need to optimize images, whether it’s GIF, JPG, PNG, or WebP format.</p>
      <p>
      We’re glad to make it known that Evano <span className={styles.hight_light}>GIF Compressor</span>, <span className={styles.hight_light}>Meme Generator</span> & <span className={styles.hight_light}>JPG File Converter</span> can handle all your image optimization needs and more. It is a GIF creator and a GIF compressor while allowing you to do a <span className={styles.hight_light}>JPG merge</span> and <span className={styles.hight_light}>JPG compress</span>. Aside from its capabilities to process GIF and JPG, it is also a <span className={styles.hight_light}>PNG Compressor</span>, a <span className={styles.hight_light}>WebP converter</span>, and a <span className={styles.hight_light}>Sprite Sheet Cutter</span> rolled into one. For all your image optimization needs, you came to the right place.
      </p>
    </div>
  </div>
)

export default OptimizeDes
