import React, { useState } from 'react'

import styles from './ConvertingSettingModal.module.sass'

import MaskedInput from 'antd-mask-input'

const TrimContainer = ({ value = {}, onChange, defaultStartValue, defaultEndValue }) => {
    const [start, setStart] = useState(defaultStartValue)
    const [end, setEnd] = useState(defaultEndValue)

    const triggerChange = changedValue => {
        if (onChange) {
            onChange({
                start,
                end,
                ...value,
                ...changedValue
            });
        }
    };

    const onTimeChange = (e) => {
        const newTime = e.target.value
        if (!(`${e.target.name}` in value)) {
            e.target.name === 'start' ? setStart(newTime) : setEnd(newTime)
        }

        triggerChange({
            [e.target.name]: newTime
        })
    }

  return (
    <div className={styles.trim_container}>
        <MaskedInput
        mask="11:11:11"
        name="start"
        onChange={onTimeChange}
        placeholder='HH:MM:SS'
        defaultValue={start}
        />
        <div className={styles.separator}>-</div>
        <MaskedInput
        mask='11:11:11'
        name="end"
        onChange={onTimeChange}
        placeholder='HH:MM:SS'
        defaultValue={end}
         />
    </div>
  )
}

export default TrimContainer
