import React from 'react'
import PropTypes from 'prop-types'
import loadScript from 'load-script'
import oAuthClient from '../../utils/oauth.js'
import { fetchJSON, getDriveUrl } from '../../utils'
import crypto from 'crypto'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let scriptLoadingStarted = false;

export default class GoogleChooser extends React.Component {

    static propTypes = {
        children: PropTypes.node,
        clientId: PropTypes.string.isRequired,
        developerKey: PropTypes.string,
        scope: PropTypes.array,
        viewId: PropTypes.string,
        authImmediate: PropTypes.bool,
        origin: PropTypes.string,
        onChange: PropTypes.func,
        onAuthenticate: PropTypes.func,
        onAuthFailed: PropTypes.func,
        createPicker: PropTypes.func,
        multiselect: PropTypes.bool,
        navHidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.object,
        isConnected: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    onAuthenticate: () => {},
    onAuthFailed: () => {},
    scope:['https://www.googleapis.com/auth/drive.file'],
    viewId: 'DOCS',
    authImmediate: false,
    multiselect: false,
    navHidden: false,
    disabled: false,
    isConnected: false,
  };

  constructor(props) {
    super(props);

    this.onApiLoad = this.onApiLoad.bind(this);
    this.onChoose = this.onChoose.bind(this);
  }

  componentDidMount() {
    if(this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad)
    } else {
      // is loading
    }
  }

  isGoogleReady() {
    return !!window.gapi;
  }

  isGoogleAuthReady() {
    return !!window.gapi.auth;
  }

  isGooglePickerReady() {
    return !!window.google.picker;
  }

  onApiLoad() {
    window.gapi.load('auth', () => {
      window.gapi.auth.init({
        client_id: this.props.clientId,
        scope: this.props.scope
      });
    });
    window.gapi.load('picker');
  }

  doAuth() {
    // window.gapi.auth.authorize({
    //     client_id: this.props.clientId,
    //     scope: this.props.scope,
    //     immediate: this.props.authImmediate
    //   },
    //   callback
    // );
    const site = `${window.location.protocol}//${window.location.host}`
    const provider = {
      authorizationUrl: 'https://accounts.google.com/o/oauth2/auth?response_type=code',
      accessTokenUrl: 'https://accounts.google.com/o/oauth2/token',
      clientId: this.props.clientId,
      callbackUrl: `${site}/callback/google-drive`,
      version: '2.0'
    }
    const client = oAuthClient(provider)
    let url = client.getAuthorizeUrl({
      redirect_uri: provider.callbackUrl,
      scope: `${this.props.scope.join(' ')} https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email`,
      state: crypto.randomBytes(64).toString('hex')
    })
    if (provider.authorizationUrl.includes('?')) {
      const parseUrl = new URL(provider.authorizationUrl)
      const baseUrl = `${parseUrl.origin}${parseUrl.pathname}?`
      url = url.replace(baseUrl, provider.authorizationUrl + '&')
    }
    url = `${url}&prompt=consent&access_type=offline`
    window.open(url, '_blank')
  }

  async onChoose() {
    if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.props.disabled) {
      return null;
    }

    let oauthToken = cookies.get('g_token')
    if (this.props.isConnected) {
      const urls = await getDriveUrl()
      if (!urls) return
      const res = await fetchJSON(`${urls.driveUrl}/user/get-online-storage-token`,
        { method: 'get',
          payload: {
            provider: 'google_drive'
          }
        })
        console.log(res.data)
        oauthToken = res.data.access_token
        const expireTime = (new Date()).getTime() + res.data.expires_in * 1000
        cookies.set('g_token', res.data.access_token, {
          path: '/',
          expires: new Date(expireTime)
        })
        cookies.set('g_refresh_token', res.data.refresh_token, {
          path: '/',
          expires: new Date(expireTime)
        })
    }

    if (oauthToken) {
      this.createPicker(oauthToken);
    } else {
      this.doAuth(response => {
        if (response.access_token) {
          this.createPicker(response.access_token)
        } else {
          this.props.onAuthFailed(response);
        }
      });
    }
  }

  createPicker(oauthToken) {

    this.props.onAuthenticate(oauthToken);

    if(this.props.createPicker){
      return this.props.createPicker(google, oauthToken)
    }

    const googleViewId = google.picker.ViewId[this.props.viewId];
    const view = new window.google.picker.View(googleViewId);

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(','))
    }
    if (this.props.query) {
      view.setQuery(this.props.query)
    }

    if (!view) {
      throw new Error('Can\'t find view by viewId');
    }

    const picker = new window.google.picker.PickerBuilder()
                             .addView(view)
                             .setOAuthToken(oauthToken)
                             .setDeveloperKey(this.props.developerKey)
                             .setCallback(this.props.onChange);

    if (this.props.origin) {
      picker.setOrigin(this.props.origin);
    }

    if (this.props.navHidden) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
    }

    picker.build()
          .setVisible(true);
  }

  render() {
    return (
      <a onClick={this.onChoose} className={this.props.className.link} href="#/">
        {
          this.props.children ?
            this.props.children :
            <button>Open google chooser</button>
        }
      </a>
    );
  }
}
