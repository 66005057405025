import React from 'react'
import { Col, Row } from 'antd'
import styles from './StepsGuide.module.sass'

const StepsGuide = ({ data, title }) => (
  <section className={styles.section_feature}>
    <div className="container">
      <Row>
        <Col
          xs={24}
          md={20}
          className={`mx-auto`}
        >
          <div className={styles.step_to_archive}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.step_intro}>
              {
                data.map(step => (
                  <div key={step.step} className={styles.step_wrapper}>
                    <div className={styles.first_line}>
                      <div className={styles.circle}>{step.step}</div>
                      <div className={styles.name}>{step.name}</div>
                    </div>
                    <div className={styles.second_line}>{step.description}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </section>
)

export default StepsGuide
