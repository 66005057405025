import { Button, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './footer.module.sass'

const LanguageSwitcher = () => {
  const languages = [
    {
      key: 'en',
      icon: '/images/en.png',
      text: 'English'
    }
  ]

  const getLanguage = (key) => {
    const item = languages.find(el => el.key === key)
    if (item) {
      return item.text
    }
    return 'English'
  }

  const menu = (
    <Menu className='language_menu'>
      {
        languages.map((item) => (
          <Menu.Item className='language_menu_item' key={item.key}>
            <img className={styles.country} src={item.icon} alt='country' />&nbsp;<span>{item.text}</span>
          </Menu.Item>
        ))
      }
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <Button className={styles.btn_language}>
        <img src={require('public/images/en.png')} width={64} height={64} className={styles.country} alt='language' />&nbsp;<span>{getLanguage('en')}</span> <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default LanguageSwitcher
