import React, { useEffect, useRef, useState } from 'react'
import styles from './footer.module.sass'
import { Col, Row } from 'antd'
import { formatter, getRandomIntInclusive, readableBytes } from '../../utils'

const arrayRandomNumber = [500, 1000, 1500, 2000, 2500]
const arrayRandomNumberSize = [2000, 5340, 4000, 7000]

const FooterArchievement = ({ totalFile, totalFileSize}) => {
  const [file, setFile] = useState(totalFile - 50)
  const [size, setSize] = useState(+readableBytes(totalFileSize).split(' ')[0] - 13)
  const suffix = readableBytes(totalFileSize).split(' ')[1]
  const savedCallbackFile = useRef()
  const savedCallbackSize = useRef()

  const randomNumber = arrayRandomNumber[getRandomIntInclusive(0, arrayRandomNumber.length - 1)]
  const delay = arrayRandomNumberSize[getRandomIntInclusive(0, arrayRandomNumberSize.length - 1)]
  const fileCondition = file > totalFile
  const sizeCondition = size > +readableBytes(totalFileSize).split(' ')[0]

  function callbackFile() {
    setFile(file + 1);
  }

  function callbackSize() {
    if (sizeCondition) {
      setSize(size + 0.01);
    } else {
      setSize(size + 1)
    }
  }

  useEffect(() => {
    savedCallbackFile.current = callbackFile
    savedCallbackSize.current = callbackSize
  })

  useEffect(() => {
    let timer
    if (fileCondition) {
      timer = setInterval(() => savedCallbackFile.current(), randomNumber);
    } else {
      timer = setInterval(() => savedCallbackFile.current(), 100);
    }

    return () => clearInterval(timer);
  }, [fileCondition, file])

  useEffect(() => {
    let timer
    if (sizeCondition) {
      timer = setInterval(() => savedCallbackSize.current(), delay);
    } else {
      timer = setInterval(() => savedCallbackSize.current(), 100);
    }

    return () => clearInterval(timer);
  }, [sizeCondition, size])

  return (
    <div className="container">
      <Row>
        <Col
          xs={24}
          md={20}
          className={`${styles.archievement} ${styles.premium} mx-auto`}
        >
          <div className={styles.archievement_text}>
            <p>
              <span>{"We've already converted"}</span>
              <span className={styles.files}>
              {
                ` ${formatter.format(file)} `
              }
              </span>
              <span>files with a total size of</span>
              <span className={styles.size}>{` ${size.toFixed(2)} ${suffix} `}</span>
            </p>
          </div>
          <img
            className={styles.logo_left}
            src={require('public/images/logo_pre_footer.png')}
            alt="logo"
          />
          <img
            className={styles.circle}
            src={require('public/images/circle.svg')}
            alt="circle"
          />
        </Col>
      </Row>
    </div>
  );
};

export default FooterArchievement;
