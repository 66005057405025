import React, { useState } from 'react'
import { Select, Slider } from 'antd'

const { Option } = Select;

const VideoCustomBitrate = ({ li, value = {}, onChange, defaultValue, defaultSliderValue }) => {
    const [slider, setSlider] = useState(defaultSliderValue)
    const [setted, setSetted] = useState(defaultValue)
    const [showSlider, setShowSlider] = useState(defaultValue === 'custom')

    const triggerChange = changedValue => {
        if (onChange) {
            onChange({
                slider,
                setted,
                ...value,
                ...changedValue
            });
        }
    };

    const onSliderChange = (val) => {
        if (!('slider' in value)) {
            setSlider(val);
        }

        triggerChange({
            slider: val
        });
    };

    const onSelectChange = (val) => {
        if (val === 'custom') {
            setShowSlider(true)
        } else {
            setShowSlider(false)
        }

        if (!('setted' in value)) {
            setSetted(val);
        }

        triggerChange({
            setted: val
        })
    }
    return (
        <div>
            <Select disabled={li.isDisabled} onChange={onSelectChange} defaultValue={defaultValue}>
                {li.options.options.map(option => (
                    <Option value={option.value} key={option.value}>{option.text}</Option>
                ))}
            </Select>
            {
                showSlider && <Slider
                onChange={onSliderChange}
                min={li.min}
                max={li.max}
                disabled={li.isDisabled}
                defaultValue={slider}
                 />
            }

        </div>
    )
}

export default VideoCustomBitrate
