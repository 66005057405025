import React from 'react'
import styles from './style.module.sass'
import Link from 'next/link'

export default function FeatureItem({link, as , isActive, title, desc, logo, logoClassName }){
  return <Link href={link} as={as}>
    <a>
      <div
        className={
          styles.itemContainer
        }
      >
        <div className={styles.converter_item}>
          <div>
            <img
              src={logo}
              alt='feature logo'
              className={`${styles.converter_logo} ${isActive ? null : styles.deactive_feature} ${logoClassName}`}
            />
          </div>
          <div>
            <h2 className={styles.converter_heading}>
              {title}
            </h2>
            <p className={styles.converter_description}>
              {desc}
            </p>
          </div>
        </div>
      </div>
    </a>
  </Link>
}
