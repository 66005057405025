import React from 'react'
import { useTranslation } from 'next-i18next'
import { Col, Layout, Row } from 'antd'
import LanguageSwitcher from './LanguageSwitcher'
import styles from './footer.module.sass'
import Link from 'next/link'

const { Footer } = Layout;

const EvanoFooter = () => {
  const { t } = useTranslation('footer');
  const cols = t('cols', { returnObjects: true });
  const company = t("company", { returnObjects: true });
  return (
    <section className="footer">
      <img className={'footer-bg'} alt={'footer'} src={require('public/images/footer_bg.webp')}/>
      <Footer className="container">
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
          <Col md={24} lg={8}>
            <div className="logo">
              <Link href="/">
                <a>
                  <img src={require('public/images/logo_light.svg')} alt="logo" width={172} height={37} />
                </a>
              </Link>
            </div>
            <p className={styles.description}>{t("description")}</p>
            <div className={styles.language_group}>
              <span>{t("language")} </span>
              <LanguageSwitcher />
            </div>
          </Col>
          <Col md={24} lg={16}>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
              {cols.map(item => (
                <Col xs={12} md={8} key={item.key}>
                  <h3 className={styles.title}>{item.text}</h3>
                  {item.items.map(el => (
                    <div key={el.key} className={styles.item}>
                      <Link href={`${el.href}`} as={el.as ?  el.as : el.href}><a>{el.text}</a></Link>
                    </div>
                  ))}
                </Col>
              ))
              // JSON.stringify(t('cols', { returnObjects: true }))
              }
              <Col sm={24} md={8}>
                <h3 className={styles.title}>{company.text}</h3>
                <div className={styles.sm_horizontal}>
                  {company.items.map(el => (
                    el.key === 'blog' ?
                    <div className={styles.item} key={el.key}>
                      <a href={`${el.href}`}>{el.text}</a>
                    </div> :
                    <div className={styles.item} key={el.key}>
                      <Link href={`${el.href}`}><a>{el.text}</a></Link>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
      <div className={styles.copy_right}>{t("copy_right")}</div>
    </section>
  );
};

export default EvanoFooter;
