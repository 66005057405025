import React from 'react'
import { Row } from 'antd'
import styles from './style.module.sass'
import Link from 'next/link'
import getDeviceConfig from '../../../hooks/Breakpoint'
import FeatureItem from '../../FeatureItem'

const Converter = ({ converter, setActive, active }) => {
  const { isSm, isXs } = getDeviceConfig()

  if (isSm || isXs) {
    return (
      <>
        {converter.map((cv, index) => (
          <div className={styles.editor_mobile} key={index}>
            <Link href={cv.link} as={cv.as}>
              <h5 className={styles.editor_heading_mobile}>
                {cv.item_title}
              </h5>
            </Link>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Row className={styles.tools_container}>
        {converter.map((cv, index) => (
            <FeatureItem key={index.toString()} title={cv.item_title} as={cv.as} logo={cv.logo}
                         desc={cv.converter_description} link={cv.link} isActive={cv.is_active}/>
          )
        )}
      </Row>
    </>
  )
}

export default Converter
