/* eslint-disable */
import { useState } from 'react'
import { Button, Message, Upload } from 'antd'
import GoogleDrive from '../Social/GoogleDrive'
import Dropbox from '../Social/Dropbox'
import LinksModal from '../Social/Links'
import styles from './RemoteUpload.module.sass'
import { fetchJSON, getDriveUrl } from '../../utils'

const uploadProviders = [
  {
    key: 'dropbox',
    icon: 'public/images/icon_dropbox.svg'
  },
  {
    key: 'google_drive',
    icon: 'public/images/icon_google_drive.svg'
  },
  // {
  //   key: 'one_drive',
  //   icon: '/images/icon_one_drive.svg'
  // },
  {
    key: 'link',
    icon: 'public/images/icon_link.svg'
  }
]

const RemoteUpload = ({
  onSelectFiles,
  onAction,
  btnAddFiles,
  btnAction,
  hasFiles,
  loading,
  storages,
  accept,
  socialIds,
  configUpload,
  isDisabled = true
 }) => {
  const [urlLoading, setUrlLoading] = useState(false)
  const [isShow, setIssShow] = useState(false)

  const onSelectFilesURL = async (files) => {
    const urls = await getDriveUrl()
    if (!urls) return
    let count = 0
    const addedFiles = []
    for (const file of files) {
      try {
        const res = await fetchJSON(`${urls.driveUrl}/analyze-file-url`, {
          method: 'post',
          payload: { url: file.link }
        })
        if (res.status === 200) {
          addedFiles.push({
            id: `${file.link}${new Date().getTime()}`,
            name: res.data.file_name,
            size: res.data.file_size,
            type: res.data.mime_tpe,
            link: file.link,
            isUrl: true
          })
        }
      } catch (err) {
        console.log(err)
        if (err.status === 500) {
          Message.error('Something went wrong, please try again.', 2)
        } else {
          Message.error(err.data.message || err.data, 2)
        }
      } finally {
        count += 1
        if (count === files.length) {
          setUrlLoading(false)
          onSelectFiles(addedFiles)
          setIssShow(false)
        }
      }
    }

  }

  return (
    <div className={styles.remote_upload_box} style={{background: hasFiles? '#FFF' : 'transparent'}}>
      {hasFiles ?
        <Upload {...configUpload} openFileDialogOnClick={true} className={styles.add_more} accept={accept}>
          <Button type='default' className={styles.remote_upload_text} disabled={loading}>{btnAddFiles}</Button>
        </Upload> : <span/>
        // <span className={styles.remote_upload_text}>
        //   Remote Upload
        // </span>
      }
      &nbsp;
      {uploadProviders.map(item => {
        switch (item.key) {
          case 'google_drive':
            return (
              <GoogleDrive key={item.key}
                GOOGLE_CLIENT_ID={socialIds.google}
                isConnected={!!storages.find(item => item.id === 'google_drive')}
                className={{ link: isDisabled ? styles.disabled : styles.remote_upload_link, img: styles.remote_upload_icon }}
                onSelectFiles={files => onSelectFiles(files)} />
            )
          case 'dropbox':
            return (
              <Dropbox key={item.key}
                DROPBOX_APP_ID={socialIds.dropbox}
                isConnected={!!storages.find(item => item.id === 'drop_box')}
                className={{ link: isDisabled ? styles.disabled : styles.remote_upload_link, img: styles.remote_upload_icon }}
                onSelectFiles={onSelectFilesURL} />
            )
          default:
            return (
              <a href='#' key={item.key} className={isDisabled ? styles.disabled : styles.remote_upload_link}>
                <img
                  src={item.icon}
                  alt='upload'
                  className={styles.remote_upload_icon}
                  onClick={() => {setIssShow(true)}}
                />
              </a>
            )
        }
      })}
      {hasFiles ?
        <Button type='default' className={styles.convert_btn} onClick={onAction} disabled={loading}>
          <img src={'/images/convert_logo_icon.svg'} alt="logo" />
          {btnAction}
        </Button> : null}
        <LinksModal isShow={isShow} onCloseLinksModal={() => {setIssShow(false)}}
          onSelectFiles={(files) => {setUrlLoading(true); onSelectFilesURL(files)}}
          loading={urlLoading} />
    </div>
  )
}

export default RemoteUpload
