import React, { useState } from 'react'
import Link from 'next/link'
import { Button, Menu, Popover } from 'antd'
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons'
import styles from './style.module.sass'
import { useDispatch } from 'react-redux'
import Tools from '../Tools'
import getDeviceConfig from '../../../hooks/Breakpoint'
import { useTranslation } from 'next-i18next'

const RightMenu = ({ mode }) => {
  const { t } = useTranslation('header')
  const toolCategory = t('tool_category', { returnObjects: true })
  const editors = t('editors', { returnObjects: true })
  const converter = t('converter', { returnObjects: true })
  const optimizer = t('optimizer', { returnObjects: true })
  const others = t('other', { returnObjects: true })
  const [active, setActive] = useState(null)
  const [key, setKey] = useState('0')
  const dispatch = useDispatch()
  const { isSm, isXs } = getDeviceConfig()
  // eslint-disable-next-line
  const handleVisibleChange = visible => {
    if (visible === false) {
      setKey('0')
      setActive(null)
    }
  }

  const logout = () => {
    dispatch(authFailure('Logout'))
  }
  const profileMenu = (
    <Menu>
      <Menu.Item>
        <Link href='/profile'>
          <a>Profile</a>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div onClick={logout}>
          Logout
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <Menu mode={mode} className={styles.container}>
      <div className={styles.alignLeftContainer}>
        <Menu.Item key='tools'>
          <Link href='/'>
            <Popover
              placement={isSm || isXs ? 'leftTop' : 'bottomRight'}
              onVisibleChange={handleVisibleChange}
              destroyTooltipOnHide
              content={
                <Tools
                  editors={editors}
                  active={active}
                  setActive={setActive}
                  keyItem={key}
                  setKey={setKey}
                  toolCategory={toolCategory}
                  converter={converter}
                  optimizer={optimizer}
                  other={others}
                />
              }
              className={styles.popover}
              overlayClassName={
                isSm || isXs
                  ? 'overwrite_tooltip_mobile'
                  : `${styles.overwrite_tooltip} overwrite_tooltip`
              }
            >
              <a>{t('tools')}&nbsp;&nbsp;{isSm || isXs && <DownOutlined />}</a>
            </Popover>
          </Link>
          {mode === 'horizontal'&&<CaretDownOutlined className={'color-white'}/>}
        </Menu.Item>
        <Menu.Item key='pricing'>
          <Link href='/pricing'>
            <a>{t('pricing')}</a>
          </Link>
        </Menu.Item>
        <Menu.Item key='about'>
          <Link href='/about'>
            <a>{t('about')}</a>
          </Link>
        </Menu.Item>
      </div>
      <Menu.Item key='myfile' className={styles.btn_my_file_container}>
        <Link href="/download">
          <Button type="default" className={styles.btn_my_file}>
            <span className={styles.num_file}/>&nbsp;
            {t("my_files")}
          </Button>
        </Link>
      </Menu.Item>
      {/* <Menu.Item
        key={token ? 'upgrade' : 'signin'}
        className='signin'
      >
        {
          token ? (
            <Link href='/pricing'>
          <Button className='btn_sign_in'>{t('upgrade')}</Button>
        </Link>
          ) : (
            <Link href='/signin'>
                <Button className='btn_sign_in'>{t('login')}</Button>
            </Link>
          )
        }
      </Menu.Item> */}
      {/* <Menu.Item
        key={token ? 'avatar' : 'signup'}
        className={token ? 'avatar' : 'signup}'}
      >
        {token ? (
          <Dropdown overlay={profileMenu} trigger='hover'>
            <div className={styles.avatar_wrapper}>
              <Avatar email={userInfo ? userInfo.email : ''} size={40} />
            </div>
          </Dropdown>
        ) : (
          <Link href='/signup'>
              <Button type='primary' className='btn_sign_up'>{t('signup')}</Button>
          </Link>
        )}
      </Menu.Item> */}
    </Menu>
  )
}

export default RightMenu
