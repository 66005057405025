import styles from './style.module.sass'
import { Layout, Menu } from 'antd'
import React from 'react'
import Editor from './Editor'
import Optimizer from './Optimizer'
import Converter from './Converter'

const renderToolsArea = (key, props) => {
  switch (true) {
    case key === '0':
      return <Converter {...props} />
    case key === '1':
      return <Optimizer {...props} />
    default:
      return  <Editor {...props} />
  }
}

export default function Tools(props) {
  const handleClick = ({ key }) => {
    props.setKey(`${key}`)
  }
  const { toolCategory } = props

  return (
    <Layout onClick={props.onClick}>
      <Layout.Sider theme='light' className={styles.slider}>
        <div className={styles.tool_category}>Tool category</div>
        <Menu
          theme='light'
          defaultSelectedKeys={['0']}
          selectedKeys={[`${props.keyItem}`]}
          className={styles.items}
          onClick={handleClick}
          onSelect={() => props.setActive(null)}
        >
          {toolCategory.map((cat, index) => (
            <Menu.Item
              key={index}
              className={`${styles.item_category} custom_item_category`}
            >
              <div>
                <div className={styles.item_category_head}>
                  {cat.item_category_head}
                </div>
                <div className={styles.item_category_body}>
                  {cat.item_category_body}
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <div
        className={styles.featuresWrapper}
      >
        <div className={styles.tools_area_title}>Tools area</div>
        { renderToolsArea(props.keyItem, props)}
      </div>
    </Layout>
  )
}
