import styles from './DragDrop.module.sass'
import { Col, Row } from 'antd'
import DragDrop from './DragDrop'
import React from 'react'

export default function DragDropContainer ({
  title,
  subtitle,
  dragDropTitle,
  dragDropSubtitle,
  type,
  customDragDrop,
  trailing
}) {
  return <section className={styles.pre_header}>
    <div className="container">
      <Row>
        <Col xs={24} md={20} className="text-center mx-auto">
          <div className={styles.titleContainer}>
            <div className={!trailing ? styles.titleAlignCenter : styles.titleAlignLeft}>
              {title && <h1 className={`${styles.slogan} mb-3`}>{title}</h1>}
              {subtitle && <p className={`${styles.sub_slogan} mb-4`}>{subtitle}</p>}
            </div>
            {trailing}
          </div>
          {!customDragDrop ? <DragDrop title={dragDropTitle} subtitle={dragDropSubtitle} type={type}/> : customDragDrop}
        </Col>
      </Row>
    </div>
    <div className={styles.mask_header}/>
  </section>

}
