import getConfig from 'next/config'

const publicRuntimeConfig = getConfig() ? getConfig().publicRuntimeConfig : {}

export const RECAPTCHA_V3_CLIENT_KEY = '6Lfrv_8UAAAAALGWXkKwQS34dm1H69aH30kWPGM5'
export const GOOGLE_CLIENT_ID = '808089659676-3qqu1pbvioh234ncbsh3ven0e1j3f46t.apps.googleusercontent.com'
export const FACEBOOK_CLIENT_ID = '226032675129532'
export const TWITTER_CLIENT_ID = '8lLNtoNnPXlLsdD4qKmGZBjvr'
export const DROPBOX_APP_ID = '5dvn4w4cw15onl9'
export const ONE_DRIVE_CLIENT_ID = 'e523a3af-be56-4411-8712-042904bde93a'
export const EVANO_DRIVE_ROOT_URL = publicRuntimeConfig.EVANO_DRIVE_ROOT_URL
export const EVANO_FRONTEND_ROOT_URL = publicRuntimeConfig.EVANO_FRONTEND_ROOT_URL

// AUTH
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

// CONNECT DRIVE
export const CONNECT_DRIVE_SUCCESS = 'CONNECT_DRIVE_SUCCESS'
export const CONNECT_DRIVE_FAILURE = 'CONNECT_DRIVE_FAILURE'
export const INIT_DRIVE = 'INIT_DRIVE'

// GET CLOUD STORAGE
export const FETCH_CLOUD_STORAGE = 'FETCH_CLOUD_STORAGE'
export const FETCH_CLOUD_STORAGE_SUCCESS = 'FETCH_CLOUD_STORAGE_SUCCESS'
export const FETCH_CLOUD_STORAGE_FAILURE = 'FETCH_CLOUD_STORAGE_FAILURE'

// LANGUAGE
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

// USER
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL'

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_FAIL'

export const UPDATE_USER_NAME_REQUEST = 'UPDATE_USER_NAME_REQUEST'
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS'
export const UPDATE_USER_NAME_FAIL = 'UPDATE_USER_NAME_FAIL'

export const UPDATE_USER_EMAIL_REQUEST = 'UPDATE_USER_EMAIL_REQUEST'
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS'
export const UPDATE_USER_EMAIL_FAIL = 'UPDATE_USER_EMAIL_FAIL'

export const DELETE_USER_ACCOUNT_REQUEST = 'DELETE_USER_ACCOUNT_REQUEST'
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS'
export const DELETE_USER_ACCOUNT_FAIL = 'DELETE_USER_ACCOUNT_FAIL'

export const GET_ACCOUNT_SOCIALS_REQUEST = 'GET_ACCOUNT_SOCIALS_REQUEST'
export const GET_ACCOUNT_SOCIALS_SUCCESS = 'GET_ACCOUNT_SOCIALS_SUCCESS'
export const GET_ACCOUNT_SOCIALS_FAIL = 'GET_ACCOUNT_SOCIALS_FAIL'

export const DELETE_SOCIAL_ACCOUNT_REQUEST = 'DELETE_SOCIAL_ACCOUNT_REQUEST'
export const DELETE_SOCIAL_ACCOUNT_SUCCESS = 'DELETE_SOCIAL_ACCOUNT_SUCCESS'
export const DELETE_SOCIAL_ACCOUNT_FAIL = 'DELETE_SOCIAL_ACCOUNT_FAIL'

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO'

// payment-settings
export const FETCH_PAYMENT_HISTORY_REQUEST = 'FETCH_PAYMENT_HISTORY_REQUEST'
export const FETCH_PAYMENT_HISTORY_SUCCESS = 'FETCH_PAYMENT_HISTORY_SUCCESS'
export const FETCH_PAYMENT_HISTORY_FAIL = 'FETCH_PAYMENT_HISTORY_FAIL'

export const FETCH_PAYMENT_LIST_REQUEST = 'FETCH_PAYMENT_LIST_REQUEST'
export const FETCH_PAYMENT_LIST_SUCCESS = 'FETCH_PAYMENT_LIST_SUCCESS'
export const FETCH_PAYMENT_LIST_FAIL = 'FETCH_PAYMENT_LIST_FAIL'

export const MAKE_DEFAULT_PAYMENT_METHOD_REQUEST = 'MAKE_DEFAULT_PAYMENT_METHOD_REQUEST'
export const MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS = 'MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS'
export const MAKE_DEFAULT_PAYMENT_METHOD_FAIL = 'MAKE_DEFAULT_PAYMENT_METHOD_FAIL'

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAIL = 'DELETE_PAYMENT_METHOD_FAIL'

// formats
export const SET_FORMATS = 'SET_FORMATS'
export const SET_FILE_LIST = 'SET_FILE_LIST'
export const UPDATE_SETTING_FILE_LIST = 'UPDATE_SETTING_FILE_LIST'
export const UPDATE_FILE_LIST = 'UPDATE_FILE_LIST'
export const UPDATE_TIME_TRIM_FOR_FILES = 'UPDATE_TIME_TRIM_FOR_FILES'
export const DELETE_FILE = 'DELETE_FILE'

// Activiy
export const FETCH_ACTIVITY_REQUEST = 'FETCH_ACTIVITY_REQUEST'
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS'
export const FETCH_ACTIVITY_FAIL = 'FETCH_ACTIVITY_FAIL'

// Contact Us
export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST'
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS'
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE'

// usage
export const FETCH_USAGE_REQUEST = 'FETCH_USAGE_REQUEST'
export const FETCH_USAGE_SUCCESS = 'FETCH_USAGE_SUCCESS'
export const FETCH_USAGE_FAILURE = 'FETCH_USAGE_FAILURE'

// Statistics
export const FETCH_STATISTICS_REQUEST = 'FETCH_STATISTICS_REQUEST'
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS'
export const FETCH_STATISTICS_FAILURE = 'FETCH_STATISTICS_FAILURE'

// media converter files
export const FETCH_MEDIA_CONVERTER_REQUEST = 'FETCH_MEDIA_CONVERTER_REQUEST'
export const FETCH_MEDIA_CONVERTER_SUCCESS = 'FETCH_MEDIA_CONVERTER_SUCCESS'
export const FETCH_MEDIA_CONVERTER_FAILURE = 'FETCH_MEDIA_CONVERTER_FAILURE'

export const DELETE_MEDIA_CONVERTER = 'DELETE_MEDIA_CONVERTER'
export const DELETE_MEDIA_CONVERTER_SUCCESS = 'DELETE_MEDIA_CONVERTER_SUCCESS'
export const DELETE_MEDIA_CONVERTER_FAILURE = 'DELETE_MEDIA_CONVERTER_FAILURE'
export const SET_SESSION_CONVERT = 'SET_SESSION_CONVERT'

// Convert settings
export const SET_CONVERT_SETTINGS = 'SET_CONVERT_SETTINGS'
// Reset convert settings
export const RESET_CONVERT_SETTINGS = 'RESET_CONVERT_SETTINGS'

// Reset file list
export const RESET_FILE_LIST = 'RESET_FILE_LIST'
// Save compress file
export const SAVE_COMPRESS_FILE = 'SAVE_COMPRESS_FILE'
