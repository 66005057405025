import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate, getDriveUrl, saveStreamingFile } from '../../utils'
// import { EVANO_DRIVE_ROOT_URL } from '../../constants'
import { Button, Col, List, message, Row } from 'antd'
import Footer from '../Footer'
import FooterArchievement from '../Footer/FooterArchievement'
import styles from './download.module.sass'
import { deleteMediaConverter, getMediaConverter, setList, setSessionConvert } from '../../redux/actions/file_list.js'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Loading from 'components/Loading'
import HeaderBar from '../HeaderBar'
import DownloadedItem from './DownloadedItem'
import { useTranslation } from 'next-i18next'

const Content = (props) => {
  const router = useRouter()
  const { mediaConvertFile, total, isFetchingMediaConverter, deleteMediaConverterResult, error, isDeletingMediaConverter } = useSelector(state => state.fileList) || {}
  const [pagination, setPagination] = useState(null)
  const [fileList, setFileList] = useState([])
  const dispatch = useDispatch()
  const [token, setToken] = useState(props.ssrToken);
  const [downloadAll, setDowndloadAll] = useState('Download Archive')
  const [page, setPage] = useState(1)
  const {t} = useTranslation()

  const { statistic } = useSelector(state => state.fileList)
  const { ids } = props
  useEffect(() => {
    if (ids || token) {
      console.log(ids)
      dispatch(getMediaConverter({ ids, page, page_size: 10 }))
    }
    // else {
    //   const token = cookies.token || props.ssrToken
    //   setToken(token)}
  }, [ids, token, page])
  useEffect(() => {
    console.log('ids, token', ids, token)
    if (!(ids || token)) {
      dispatch(getMediaConverter({ ids: null, page, page_size: 10 }))
    }
  }, [])
  useEffect(() => {
    console.log('ids, token', ids, token)
    if (!(ids || token)) {
      dispatch(getMediaConverter({ ids: null, page, page_size: 10 }))
    }
  }, [page])


  useEffect(() => {
    if (error) {
      message.error(error.message)
    }
  }, [error])

  useEffect(() => {
    if (deleteMediaConverterResult) {
      console.log('delete success')
      dispatch(getMediaConverter({ ids, page, pageSize: 10}))
    }
  }, [deleteMediaConverterResult])

  useEffect(() => {
      console.log(mediaConvertFile)
      const fileList = []
      if (mediaConvertFile) {
        mediaConvertFile.map(file => {
          if (file.status === 'success') {
            file.output_files.forEach(item => {
              fileList.push({
                name: item.file_name,
                format: item.file_name.substr(item.file_name.lastIndexOf('.') + 1),
                converted_size: item.file_size,
                url: item.url,
                media_id: item.id,
                downloadStatus: '',
                downloadText: 'Download',
                convertStatus: 'success',
                expired: file.expired_at
              })
            })
          } else if (file.status === 'fail') {
            file.output_files.forEach(item => {
              fileList.push({
                name: item.file_name,
                format: item.file_name.substr(item.file_name.lastIndexOf('.') + 1),
                converted_size: item.file_size,
                url: '',
                media_id: item.id,
                downloadStatus: '',
                downloadText: '',
                convertStatus: 'fail',
                errorMessage: file.error_msg || 'Unknown error',
                expired: ''
              })
            })
          }
        })
      }
      if (total > 10) {
        setPagination({
          onChange: page => setPage(page),
          pageSize: 10,
          total,
          current: page,
          showSizeChanger: false
        })
      } else {
        setPagination(null)
      }
      console.log(fileList)
      setFileList(fileList)
  }, [mediaConvertFile])

  const onDeleteFile = (file) => {
    console.log(file)
    if (token) {
      dispatch(deleteMediaConverter(file))
    } else {
      let newFileList = fileList.slice()
      let index = -1
      newFileList.forEach((element, fileIndex) => {
        if(element.id === file.id) {
          index = fileIndex
        }
      });
      if (index > -1) {
        newFileList.splice(index, 1)
        if (newFileList.length === 0) {
          localStorage.removeItem('files')
          router.push('/')
        } else {
          setFileList(newFileList)
          localStorage.setItem('files', JSON.stringify(newFileList))
        }
      }
    }
  }

  const onDownloadALL = async () => {
    setDowndloadAll('Downloading')
    if (fileList.length > 1) {
      let query = ''
      fileList.forEach(item => {
        if (item.id !== '') {
          query += `&files_id=${item.id}`
        }
      })
      const urls = await getDriveUrl()
      if (urls) {
        const url = `${urls.driveUrl}/files/download?${query.substr(1)}`
        const filename = `Evano_${formatDate(new Date(), 'yyyy_MM_dd')}.zip`
        saveStreamingFile(url, filename, (status, error) => {
          setDowndloadAll('Download Archive',)
        })
      }

    }
  }


  // useEffect(() => {
  //   try {
  //     dispatch(fetchStatistics())
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [])


  return (
    <>
      <Head>
        <title>Download - Evano</title>
      </Head>
      <HeaderBar token={token} />
      <section className={styles.pre_header}>
        <div className='container'>
          <Row>
            <Col xs={24} md={20} className='text-center mx-auto'>
              <h1 className={styles.title}>Conversion completed!</h1>
              <p className={styles.subtitle}>Download your converted files</p>

              <div className={styles.box_wrap}>
                <div className={styles.box_top}>
                  <span className={styles.red} />
                  <span className={`${styles.red} ${styles.yellow}`} />
                  <span className={`${styles.red} ${styles.green}`} />
                </div>
                <div className={styles.box_inner}>
                  <div className={styles.files}>
                    {(fileList && fileList.length > 0) &&
                      <List
                        bordered
                        loading={{
                          spinning: isFetchingMediaConverter,
                          indicator: <Loading width={200} height={200} />
                        }}
                        pagination={pagination}
                        dataSource={fileList}
                        renderItem={(file) => <DownloadedItem file={file} key={file?.id?.toString()}/>}
                      />
                    }
                  </div>

                    <Row justify='end' style={{marginBottom: '2rem'}}>
                      <Col>
                        <Button className={styles.download_all}
                          onClick={() => {
                            dispatch(setSessionConvert(''))
                            dispatch(setList([]))
                            setTimeout(() => {
                              router.push('/')
                            }, 300)
                          }
                          }>
                            Convert more
                        </Button>
                        {/* {(fileList && fileList.length > 1) &&
                          <Button className={styles.download_all}
                            onClick={onDownloadALL}
                            disable={downloadAll === 'Downloading'}>
                              {downloadAll}
                          </Button>
                        } */}
                      </Col>
                    </Row>
                    {/*{ isSm || isXs ?*/}
                    {/*  (<p key='ads-dragdrop-mobile' className={styles.adsbygoogle_wrapper_mobile}>*/}
                    {/*    /!* <ins className="adsbygoogle"*/}
                    {/*      style={{display: 'block'}}*/}
                    {/*      data-ad-client="ca-pub-6022273213615485"*/}
                    {/*      data-ad-slot="5558781969"*/}
                    {/*      data-ad-format="auto"*/}
                    {/*      data-full-width-responsive="true"></ins> *!/*/}
                    {/*      <GoogleAdsense*/}
                    {/*        className="adsbygoogle"*/}
                    {/*        style={{display: 'block', 'min-width': '200px', 'min-height': '50px'}}*/}
                    {/*        client='ca-pub-6022273213615485'*/}
                    {/*        slot='5558781969'*/}
                    {/*        format='auto'*/}
                    {/*        responsive={true} />*/}
                    {/*  </p>) :*/}
                    {/*  (<p key='ads-dragdrop-desktop' className={styles.adsbygoogle_wrapper}>*/}
                    {/*    /!* <ins className="adsbygoogle"*/}
                    {/*        style={{display: 'block'}}*/}
                    {/*        data-ad-client="ca-pub-6022273213615485"*/}
                    {/*        data-ad-slot="1811108645"*/}
                    {/*        data-ad-format="auto"*/}
                    {/*        data-full-width-responsive="true"></ins> *!/*/}
                    {/*    <GoogleAdsense*/}
                    {/*      className="adsbygoogle"*/}
                    {/*      style={{display: 'block', 'min-width': '200px', 'min-height': '50px'}}*/}
                    {/*      client='ca-pub-6022273213615485'*/}
                    {/*      slot='1811108645'*/}
                    {/*      format='auto'*/}
                    {/*      responsive={true} />*/}
                    {/*  </p>)*/}
                    {/*}*/}
                </div>
              </div>
            </Col>
          </Row>
        </div>

      </section>

      <section className='pre_footer'>
      { statistic ? <FooterArchievement totalFile={statistic.total_file} totalFileSize={statistic.total_file_size}/> : null }
      </section>

      <Footer />
    </>
  )
}

export default Content
