import { Grid } from 'antd'

const { useBreakpoint } = Grid

const getDeviceConfig = () => {
  const screens = useBreakpoint()
  const device = {
    isXs: false,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isXxl: false,
  }
  let checkDevice = {}
  const count = Object.entries(screens).filter(screen => !!screen[1]).length

  if (count === 1 && screens.xs) {
    checkDevice = { ...device, isXs: true }
  }

  if (count === 1 && !screens.xs) {
    checkDevice = { ...device, isSm: true }
  }

  if (count === 2 && !screens.xs) {
    checkDevice = { ...device, isMd: true }
  }

  if (count === 3 && !screens.xs) {
    checkDevice = { ...device, isLg: true }
  }

  if (count === 4 && !screens.xs) {
    checkDevice = { ...device, isXl: true }
  }

  if (count === 5 && !screens.xs) {
    checkDevice = { ...device, isXxl: true }
  }

  return checkDevice
}

export default getDeviceConfig
