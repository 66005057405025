import React from 'react'
import styles from './DesCard.module.sass'

const AudioEditorDes = () => (
  <div className={styles.compression_text}>
    <h3 className={styles.title_desc}>About Audio Editor</h3>
    <div className={styles.content_desc}>
      <p>
      In our day and age, audio plays a significant role in our daily living. Have you tried watching a movie or skimming videos on social media without a sound? If videos nowadays are edited without the right audio and music, it will lose its power the same way Thor does without his hammer. Or Peter Parker without his Spidey sense. OK, alright, you get the point. That is why a capable <span className={styles.hight_light}>audio editor</span> or an <span className={styles.hight_light}>MP3 editor online</span> is an absolute necessity so you can manipulate audio for personal benefits.
      </p>
      <p>
      Having said that, we could not restate enough how essential it is to have a trusted online audio editing software or a simple <span className={styles.hight_light}>MP3 converter</span>. You may find yourself in the middle of nowhere without your equipment, perhaps only a phone in hand, needing to perform a quick audio or MP3 conversion or the functions of a reliable <span className={styles.hight_light}>audio splitter</span> or <span className={styles.hight_light}>MP3 cutter</span>. Or, if you’re just starting to get your feet wet in the audio editing world, you may not be able to afford paying 50 bucks a month for the most popular audio editing software suite.
      </p>
      <p>
      In any case, if music is your life, situations like those mentioned above are not out of the realm of impossibility. We recognize that times like these are where online audio editors and MP3 converters earn their stripes. That’s why we are making it possible to have the essential audio editing tools-- <span className={styles.hight_light}>MP3 trimmer</span>, <span className={styles.hight_light}>MP3 joiner</span>, <span className={styles.hight_light}>audio converter</span>, and more-- in one convenient place.
      </p>
      <h3>What Makes An Audio Editor Worth Your Time</h3>
      <p>If an audio editor online has all the necessary tools of the trade, it will be able to perform the job it's supposed to do. Some of the audio editing bread and butter are microphone recording, removing audio from a particular timeline, trimming an audio file, combining audio files, and converting audio files from one format to another.</p>
      <p>The features that we will mention below are not often found in basic audio editors, but if your go-to audio editors have them, consider them for keeps:</p>
      <ul className={styles.list}>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>An unobtrusive user interface that does not get in the way of the smooth workflow.</p>
        </li>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>Audio enhancement features, such as compression and mixing.</p>
        </li>
        <li className={styles.item}>
          <img src={'/images/check.svg'} alt="check icon" className={styles.image} />
          <p>Supports a bunch of audio file formats in conversions. Not everyone needs this, but to a few, this feature could be crucial. Some simple editors may only deal with one type of uncompressed audio format such as WAV or common lossy compression formats like MP3 and AAC. If an audio converter deals with much more than basic formats, that's an excellent tool to have at your disposal.</p>
        </li>

        </ul>
        <p>
        Not to toot our own horn, but Evano's audio editor and MP3 converter online do have these features along with the basic tenets of audio editing. What's even better is, you can start using our platform absolutely free of charge!
        </p>
        <p>
        Of course, you may find yourself managing a heightened workload in the future. In those circumstances, you will have to be subscribed to a paid plan depending on your needs. For now, we will gladly encourage you to click and explore the plethora of tools below
        </p>
    </div>
  </div>
)

export default AudioEditorDes
