/* eslint-disable */
// import { EVANO_API_ROOT_URL } from '../constants'
import oAuthClient from './oauth'
import { format } from 'date-fns'
import Axios from 'axios'
import crypto from 'crypto'
import { Cookies } from 'react-cookie'
import jwtDecode from 'jwt-decode'
import { v4 as uuidv4 } from 'uuid'
import * as formats from '../formats.json'

const md5 = require('md5')
const cookies = new Cookies()

export const axios = Axios.create({
  baseURL: '/api',
  withCredentials: false
})

const makeRequestData = ({ method, payload, headers = {}, handleProgress = null }) => {
  if (headers) {
    Object.assign({
      'content-type': 'application/json',
      accept: 'application/json'
    }, headers)
  }
  if (process.browser && localStorage.getItem('evano_sid')) {
    headers.authorization = `SID ${localStorage.getItem('evano_sid')}`
  }
  if (cookies.get('token')) {
    headers.authorization = cookies.get('token')
  }
  if (method === 'get' || method === 'delete') {
    return [{
      headers,
      params: payload
    }]
  } else if (method === 'post' || method === 'put' || method === 'patch') {
    const options = {}
    if (typeof handleProgress === 'function') {
      options.onUploadProgress = handleProgress
    }
    return [
      payload,
      {
        headers,
        ...options
      }
    ]
  }
}

export const fetchJSON = (url, request) =>
  new Promise((resolve, reject) => {
    const requestData = makeRequestData(request)
    // eslint-disable-next-line
    return axios[request.method](url, ...requestData)
      .then(response => {
        if (response.status >= 300) {
          reject(response)
        } else if (response.status === 204) {
          resolve({data: 'success'})
        } else {
          resolve(response)
        }
      })
      .catch(error => {
        if (error && error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })

export const createEvanoSID = () => {
  if (!localStorage.getItem('evano_sid')) {
    localStorage.setItem('evano_sid', uuidv4())
  }
}

export const hash = (message) => {
  return md5(message)
}

export const authHeaders = (token) => {
  return {
    'content-type': 'application/json',
    authorization: token
  }
}

export const formatDate = (date, dateFormat) => {
  return format(date, dateFormat)
}

export const getCurrencySymbol = currency => {
  switch (true) {
    case currency === 'EUR':
      return '€'
    case currency === 'GBP':
      return '£'
    default:
      return '$'
  }
}

export const oauthSigninUrl = (provider, callback) => {
  const client = oAuthClient(provider)
  if (provider.version && provider.version.startsWith('2.')) {
    // Handle oAuth v2.x
    let url = client.getAuthorizeUrl({
      client_id: provider.clientId,
      redirect_uri: provider.callbackUrl,
      scope: provider.scope,
      state: crypto.randomBytes(64).toString('hex')
    })

    // If the authorizationUrl specified in the config has query parameters on it
    // make sure they are included in the URL we return.
    //
    // This is a fix for an open issue with the oAuthClient library we are using
    // which inadvertantly strips them.
    //
    // https://github.com/ciaranj/node-oauth/pull/193
    if (provider.authorizationUrl.includes('?')) {
      const parseUrl = new URL(provider.authorizationUrl)
      const baseUrl = `${parseUrl.origin}${parseUrl.pathname}?`
      url = url.replace(baseUrl, provider.authorizationUrl + '&')
    }
    callback(null, url)
  } else {
    // client.getOAuthRequestToken((error, oAuthToken) => {
    //   if (error) {
    //     console.error('GET_AUTHORISATION_URL_ERROR', error)
    //   }
    //   const url = `${provider.authorizationUrl}?oauth_token=${oAuthToken}`
    //   callback(error, url)
    // }, provider.callbackUrl)
    Axios.post('/api/request-token-oauth-v1', {
      ...provider
    }).then(res => {
      callback(null, `${provider.authorizationUrl}?oauth_token=${res.data.oauth_token}`)
    }).catch(err => {
      console.log(err)
      callback(err, null)
    })
  }
}

export const tokenExpireTime = (token) => {
  if (token) {
    const decode = jwtDecode(token)
    return new Date(decode.exp * 1000)
  }
  return new Date()
}

export const setCookieExpireTime = (timestamp) => {
  return new Date(timestamp * 1000)
}

export const verifyToken = (token) => {
  if (token) {
    const decode = jwtDecode(token)
    if (decode.exp < Math.floor(new Date().getTime() / 1000)) {
      console.log(decode.exp)
      console.log('now', Math.floor(new Date().getTime() / 1000))
      return false
    }
    return true
  }
  return false
}

export const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        const [key, value] = param.split('=')
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        return params
      }, {}
      )
    : {}
}

export const getFullUrl = (ctx) => {
  if (ctx.req) {
    // Server side rendering
    return ctx.req.protocol + '://' + ctx.req.get('host') + ctx.req.originalUrl
  } else {
    // Client side rendering
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
  }
}

export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64)
  var binaryLen = binaryString.length
  var bytes = new Uint8Array(binaryLen)
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

export const saveByteArray = (data, name) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  // eslint-disable-next-line
  const blob = new Blob(data)
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}

export const saveStreamingFile = (url, filename, callback) => {
  axios({
    url, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', filename); //or any other extension
     document.body.appendChild(link);
     link.click();
     callback(true, null)
     window.URL.revokeObjectURL(url)
  }).catch(err => {
    callback(false, err)
  })
}

export const readableBytes = (bytes) => {
  if (!bytes) return '0'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i]
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

export let formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
})

export const getDriveUrl = async () => {
  try{
    const res = await fetchJSON('/request-drive-url', {method: 'get'})
    if (res.status === 200) {
      return {driveUrl: `${res.data.EVANO_DRIVE_ROOT_URL}/v1`,
        socketUrl: res.data.EVANO_WEBSOCKET_ROOT_URL
      }
    }
    return null
  } catch (err) {
    return null
  }
}

export const getSocialIds = async () => {
  try{
    const res = await fetchJSON('/request-social-ids', {method: 'get'})
    if (res.status === 200) {
      return res.data
    }
    return ''
  } catch (err) {
    return {}
  }
}

export function getAvatar(data = {}) {
  return ((data['_embedded']
    && data['_embedded']['author']
    && data['_embedded']['author'][0]
    && data['_embedded']['author'][0]['avatar_urls']
    && data['_embedded']['author'][0]['avatar_urls']['96']) || '/images/webclip.png')
}

export function getAuthorName(data = {}) {
  return (
    (data['_embedded']
      && data['_embedded']['author']
      && data['_embedded']['author'][0]
      && data['_embedded']['author'][0]['name']) || 'Admin')
}

export const getFileFormat = (fileName) => {
  if (!fileName) return
  const gzExcept = ['tar.xz', 'tar.lzo', 'tar.lzma', 'tar.lz', 'tar.bz', 'tar.7z']
  const fileMap = {
    "tgz": ["tgz", "tar.gz", "gz"],
    "tar.bz": ["tar.gz", "tbz", "bz"],
    "tbz2": ["tbz2", "tar.bz2", "bz2"],
    "tar.xz": ["xz", "txz", "tar.xz"]
  }
  let ext = fileName.substr(fileName.lastIndexOf('.') + 1)
  gzExcept.forEach(tar => {
    if (tar.indexOf(ext) > -1 && fileName.indexOf(tar) > -1) {
      ext = tar
      return ext
    }
  })
  Object.keys(fileMap).forEach(key => {
    if (fileMap[key].includes(ext)) {
      ext = key
    }
  })

  return ext
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getParamUrl = ( name, url ) => {
  if (!url) return null;
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  const regexS = "[\\?&]"+name+"=([^&#]*)";
  const regex = new RegExp( regexS );
  const results = regex.exec( url );
  return results == null ? null : results[1];
}

export const getFileType = (ext) => {
  let fileType = ''
  if (ext && typeof ext == 'string') {
    Object.keys(formats.default).forEach(type => {
      if(formats.default[type].includes(ext.toUpperCase())) {
        fileType = type
        return
      }
    })
  }
  return fileType
}

export const checkExpiredDate = (exp) => {
  const diff = new Date(exp).getTime() - new Date().getTime()
  return diff
}

export const getElementByXpath = (path) => {
  return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
}

export const linSpace = (startValue, stopValue, cardinality) => {
  var arr = [];
  var step = (stopValue - startValue) / (cardinality - 1);
  for (var i = 0; i < cardinality; i++) {
    arr.push(startValue + (step * i));
  }
  return arr;
}

export const audioFadeEffect = (audio, duration, isFadeIn) => { // isFadeIn true => FadeIn, isFadeIn false => FadeOut
  let volume = 0;
  let setVolume = audio.volume < 0.5 ? 0.5 : audio.volume; // Target volume level for new song
  if (!isFadeIn) {
    setVolume = 0.1
    volume = audio.volume < 0.5 ? 0.5 : audio.volume
  }
  const speed = Math.abs(setVolume - volume)/duration/5; // Rate of increase
  audio.volume = volume;
  const eAudio = setInterval(function(){
      volume = isFadeIn ? volume + speed : volume - speed;
      console.log('volume', parseFloat(volume.toFixed(1)))
      audio.volume = parseFloat(volume.toFixed(1));
      if((isFadeIn && volume.toFixed(1) >= setVolume) ||
        (!isFadeIn && volume.toFixed(1) <= setVolume)){
        clearInterval(eAudio);
      };
  },200);
};
