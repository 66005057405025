import React, { useState } from 'react'
import { Input, Select } from 'antd'
import styles from './ConvertingSettingModal.module.sass'

const { Option } = Select;

const VideoResolution = ({ li, value = {}, onChange, defaultValue, defaultWidth, defaultHeight }) => {
  const [width, setWidth] = useState(defaultWidth)
  const [height, setHeight] = useState(defaultHeight)
  const [setted, setSetted] = useState(defaultValue)
  const [showDobleInputs, setShowDobleInputs] = useState(defaultValue === 'custom')

  const triggerChange = changedValue => {
      if (onChange) {
          onChange({
              width,
              height,
              setted,
              ...value,
              ...changedValue
          });
      }
  };

  const onDimensionChange = (e) => {
      const newDimension = parseInt(e.target.value || 0, 10);
      const dimension = e.target.name

      if (Number.isNaN(newDimension)) {
          return;
      }

      if (!(`${dimension}` in value)) {
          dimension === 'width' ? setWidth(newDimension) : setHeight(newDimension);
      }

      triggerChange({
          [dimension]: newDimension
      });
  };

  const onSelectChange = (val) => {
      if (val === 'custom') {
          setShowDobleInputs(true)
      } else {
          setShowDobleInputs(false)
      }

      if (!('setted' in value)) {
          setSetted(val);
      }

      triggerChange({
          setted: val
      })
  }

  return (
      <div>
          <Select defaultValue={defaultValue} disabled={li.isDisabled} onChange={onSelectChange}>
              {li.options.options.map(option => (
                  <Option value={option.value} key={option.value}>{option.text}</Option>
              ))}
          </Select>
          {
              showDobleInputs && (
                  <div className={styles.double_input}>
                      <Input
                          placeholder='with'
                          type="text"
                          value={value.width || width}
                          onChange={onDimensionChange}
                          name='width'
                          defaultValue={defaultWidth}
                          disabled={li.isDisabled}
                      />
              x
                      <Input
                          placeholder="height"
                          name='height'
                          type="text"
                          value={value.height || height}
                          onChange={onDimensionChange}
                          defaultValue={defaultHeight}
                          disabled={li.isDisabled}
                      />
                  </div>
              )
          }

      </div>
  )
}

export default VideoResolution
