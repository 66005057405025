import React from 'react'
import { Row } from 'antd'
import styles from './style.module.sass'
import Link from 'next/link'
import getDeviceConfig from '../../../hooks/Breakpoint'
import FeatureItem from '../../FeatureItem'

const Optimizer = ({ optimizer }) => {
  const { isSm, isXs } = getDeviceConfig()

  if (isSm || isXs) {
    return (
      <>
        {optimizer.map((opt, index) => (
          <div key={index} className={styles.editor_mobile}>
            <Link href={opt.link}>
              <h5 className={styles.editor_heading_mobile}>{opt.optimizer_heading}</h5>
            </Link>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Row className={styles.tools_container}>
        {optimizer.map((opt, index) => (
          <FeatureItem key={index.toString()} title={opt.optimizer_heading} logo={opt.logo}
                       desc={opt.optimizer_description} link={opt.link}/>
        ))}
      </Row>
    </>
  )
}

export default Optimizer
